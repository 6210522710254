import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';

import {
  Box,
  Button,
  Container,
  createStyles,
  Fade,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import clsx from 'clsx';

import { AppLinearProgress } from './AppLinearProgress';
import { DeviceContext } from '../contexts';
import { ButtonTitleWithLoading } from './ButtonTitleWithLoading';
import { ActiveOnboardingStep, OnboardingStep } from '../types';

export const useOnboardingStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        paddingTop: theme.spacing(5),
        height: '100%',
      },
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      paddingTop: theme.spacing(3),
    },
    root: {
      maxWidth: '60%',
      flexGrow: 1,
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '75%',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '95%',
      },
    },
    stepPaper: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
    },
    nextButton: {
      width: '150px',
      height: '56px',
      marginLeft: theme.spacing(10),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(3),
      },
    },
    nextBlock: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        width: '100%',
        bottom: 0,
        left: 0,
        zIndex: 1,
      },
    },
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        height: 'calc(100vh - 45vh)',
      },
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
      },
    },
    items: {
      [theme.breakpoints.up('md')]: {
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        marginBottom: theme.spacing(0),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(24),
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(20),
      },
    },
  })
);

interface Props {
  methods: any;
  handleNext: Function;
  steps: OnboardingStep[];
  loading: boolean;
  activeStep: ActiveOnboardingStep;
  setActiveStep: (step: ActiveOnboardingStep) => void;
  currentMessage: string;
  lastStepButtonLabel?: string;
  children?: React.ReactNode;
  disableNext?: boolean;
}

export const AppOnboarding = forwardRef(
  (
    {
      activeStep,
      setActiveStep,
      steps,
      methods,
      handleNext,
      loading,
      currentMessage,
      lastStepButtonLabel = 'Save',
      children,
      disableNext = false,
    }: Props,
    ref
  ) => {
    const classes = useOnboardingStyles();
    const stepsLength = steps.length;
    const [progress, setProgress] = React.useState<number>(0);
    const scrollRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useContext(DeviceContext);

    useImperativeHandle(ref, () => ({
      nextStep(jumpTo?: number) {
        const currentStepNumber = jumpTo || activeStep.number + 1;
        scrollTo(currentStepNumber);
        setActiveStep({ step: steps[currentStepNumber], number: currentStepNumber });
        const partOfProgress = 100 / (stepsLength - 1);
        setProgress(value => {
          if (value === 100) return value;
          return currentStepNumber * partOfProgress;
        });
      },
      scrollDown() {
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
      },
    }));

    const scrollTo = (step: number) => {
      setTimeout(() => {
        document.getElementById(`last-element-${step}`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 400);
    };

    useEffect(() => {
      if (activeStep.number === stepsLength - 1) {
        scrollTo(activeStep.number);
        setActiveStep(activeStep);
        setProgress(100);
      }
    }, [activeStep]);

    const getHeight = () => {
      switch (activeStep.number) {
        case 0:
          return 'calc(100vh - 40vh)';
        case 1:
          return 'calc(100vh - 35vh)';
        case 2:
          return 'calc(100vh - 30vh)';
        case 3:
          return 'calc(100vh - 25vh)';
        default:
          return 'calc(100vh - 20vh)';
      }
    };

    useEffect(() => {
      const step = 100 / (stepsLength - 1);
      setProgress(activeStep.number ? activeStep.number * step : step);
    }, [stepsLength, activeStep]);

    return (
      <Container component="main" maxWidth="lg" className={classes.mainContainer}>
        <div className={classes.paper}>
          <div className={classes.root}>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(handleNext)}
                className={classes.form}
                style={{ height: !isMobile ? getHeight() : 'auto' }}>
                <div className={classes.items}>
                  {steps.map((step, index) => (
                    <div key={step.name}>
                      {activeStep.number >= index && (
                        <Fade in={true} timeout={200}>
                          <div id={`last-element-${index}`}>
                            <Paper variant="outlined" className={classes.stepPaper}>
                              {step.component}
                            </Paper>
                          </div>
                        </Fade>
                      )}
                    </div>
                  ))}

                  <div ref={scrollRef} />
                </div>

                <Paper variant="outlined" className={clsx(classes.nextBlock, classes.stepPaper)}>
                  {!!children && <Box marginBottom={2}>{children}</Box>}
                  <Box display="flex" alignItems="center">
                    <Box display="flex" flex={1} flexDirection="column" justifyContent="center">
                      <AppLinearProgress variant="determinate" value={progress} />
                      <Typography color="textSecondary" variant="caption">
                        {currentMessage}
                      </Typography>
                    </Box>

                    <Button
                      disabled={loading || disableNext}
                      color="primary"
                      className={classes.nextButton}
                      variant="contained"
                      cy-test-id="onboarding-next-btn"
                      type="submit">
                      <ButtonTitleWithLoading
                        title={activeStep.number === stepsLength - 1 ? lastStepButtonLabel : 'Next'}
                        loaderVariant="paper"
                        loading={loading}
                      />
                    </Button>
                  </Box>
                </Paper>
              </form>
            </FormProvider>
          </div>
        </div>
      </Container>
    );
  }
);
