import React, { useContext, useRef, useState } from 'react';

import { Box, Typography, Container, Paper, Button } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  AppOnboarding,
  ButtonTitleWithLoading,
  OnboardingStepTitle,
  useOnboardingStyles,
} from '../../../components';
import {
  ActiveOnboardingStep,
  AI_MODEL,
  OnboardingStep,
  RequestWorkerData,
  TagGroups,
  UpdateWorkerData,
  Worker,
} from '../../../types';
import { getWorkerExperienceMonths, MAX_BIO_CHARACTERS } from './WorkerProfessionalInfo';
import { AppTextField } from '../../../components/inputs';
import { askAIRequest } from '../../../api/ai';
import { DeviceContext } from '../../../contexts';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Form {
  jobTitle: string;
  city: string;
  yearsOfExperience: string;
  skills: string;
  goals: string;
}

interface Props {
  jobTitle?: string;
  city?: string;
  worker?: Worker;
  updateWorker: ({ showNotification, data, handleServerError }: UpdateWorkerData) => void;
}

export const AiBioGenerator: React.FC<Props> = ({ jobTitle, city, worker, updateWorker }) => {
  const classes = useOnboardingStyles();
  const history = useHistory();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [generatedBio, setGeneratedBio] = useState<string>('');
  const [showResult, setShowResult] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);
  const monthsOfExperience = getWorkerExperienceMonths(worker?.positions || []);
  const skills = worker?.tags
    ?.filter(tag => tag.group === TagGroups.SKILL)
    ?.map(skill => skill.name)
    ?.join(', ');
  const childRef = useRef<any>();

  const yearsOfExperience = (monthsOfExperience / 12).toFixed(0);
  const methods = useForm<Form>({
    defaultValues: {
      jobTitle,
      city,
      yearsOfExperience: yearsOfExperience,
      skills,
      goals: '',
    },
  });
  const { control } = methods;
  const [loading, setLoading] = useState<boolean>(false);

  const steps: OnboardingStep[] = [
    {
      name: 'Greetings',
      component: (
        <Box>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Vyce AI generated Bio
          </Typography>

          <Typography variant="caption" color="textSecondary">
            Please answer a few short questions first. More detailed answers will result in a better, more
            personal “About me” section.
          </Typography>
        </Box>
      ),
    },
    {
      name: 'JobTitleStep',
      component: (
        <Box>
          <OnboardingStepTitle text="What's your job title?" />

          <Controller
            control={control}
            name="jobTitle"
            render={({ field }) => (
              <AppTextField
                {...field}
                label="Job Title"
                placeholder="Write here"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Box>
      ),
    },
    {
      name: 'CityStep',
      component: (
        <Box>
          <OnboardingStepTitle text="Which city do you live in?" />

          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <AppTextField {...field} label="City" placeholder="Write here" margin="normal" fullWidth />
            )}
          />
        </Box>
      ),
    },
    {
      name: 'YearsOfExperienceStep',
      component: (
        <Box>
          <OnboardingStepTitle text="How many years of experience do you have?" />

          <Controller
            control={control}
            name="yearsOfExperience"
            render={({ field }) => (
              <AppTextField
                {...field}
                label="Years of experience"
                type="number"
                placeholder="Write here"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Box>
      ),
    },
    {
      name: 'SkillsStep',
      component: (
        <Box>
          <OnboardingStepTitle text="What are your best skills?" />

          <Controller
            control={control}
            name="skills"
            render={({ field }) => (
              <AppTextField
                multiline
                {...field}
                label="Skills"
                placeholder="Answer here"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Box>
      ),
    },
    {
      name: 'GoalsStep',
      component: (
        <Box>
          <OnboardingStepTitle text="What are your career goals?" />

          <Controller
            control={control}
            name="goals"
            render={({ field }) => (
              <AppTextField
                multiline
                {...field}
                label="Goals"
                placeholder="Answer here"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Box>
      ),
    },
  ];
  const [activeStep, setActiveStep] = useState<ActiveOnboardingStep>({ step: steps[0], number: 0 });

  const onGenerate = async (data: Form) => {
    try {
      setLoading(true);
      const content = `Generate Bio suitable for CV based on this data (do not add title, max number of characters is ${MAX_BIO_CHARACTERS}) - 
      ${data.jobTitle ? `job title: ${data.jobTitle} ,` : ''} 
      ${!!data.city && `city: ${data.city} ,`} 
      ${!!data.yearsOfExperience && `years of experience: ${data.yearsOfExperience} ,`} 
      ${!!data.skills && `skills: ${data.skills} ,`}
      ${!!data.goals && `goals: ${data.goals} ,`} `;
      const res = await askAIRequest([{ role: 'assistant', content }], AI_MODEL.GPT_4O_MINI);
      const resContent = res.data.body?.message?.content || '';
      setGeneratedBio(resContent);
      setShowResult(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const publish = async () => {
    const dataToUpdate: RequestWorkerData = {
      biography: generatedBio,
    };
    setLoading(true);
    try {
      await updateWorker({ data: dataToUpdate, showNotification, handleServerError });
      setShowResult(false);
      history.push({ pathname: history.location.pathname });
    } catch (e) {
      setLoading(false);
    }
  };

  const handleNext = async (data: Form) => {
    if (activeStep.number === steps.length - 1) {
      onGenerate(data);
    } else {
      childRef?.current?.nextStep();
    }
  };

  return (
    <Box>
      {showResult ? (
        <Container component="main" maxWidth="lg" className={classes.mainContainer}>
          <div className={classes.paper}>
            <div className={classes.root}>
              <Box display="flex" justifyContent="center" marginTop={2}>
                <Button
                  style={{ minWidth: 218 }}
                  fullWidth={isMobile}
                  size="large"
                  onClick={() => setShowResult(false)}
                  color="primary"
                  variant="outlined">
                  Generate again
                </Button>
              </Box>

              <Paper className={classes.stepPaper} variant="outlined">
                <Typography style={{ fontWeight: 500, fontSize: 20 }}>Your new bio is ready!</Typography>

                <Typography variant="caption" color="textSecondary">
                  Feel free to edit it however you want before publishing it.
                </Typography>
              </Paper>

              <Paper className={classes.stepPaper} variant="outlined">
                <OnboardingStepTitle text="Your Vyce AI generated bio:" />

                <AppTextField
                  multiline
                  value={generatedBio}
                  onChange={e => setGeneratedBio(e.target.value)}
                  label="Bio"
                  margin="normal"
                  fullWidth
                />
              </Paper>

              <Box display="flex" justifyContent="center" marginTop={2}>
                <Button
                  fullWidth={isMobile}
                  style={{ minWidth: 218 }}
                  size="large"
                  onClick={publish}
                  color="primary"
                  variant="contained">
                  <ButtonTitleWithLoading
                    title=" Publish to my Profile"
                    loaderVariant="paper"
                    loading={loading}
                  />
                </Button>
              </Box>
            </div>
          </div>
        </Container>
      ) : (
        <AppOnboarding
          activeStep={activeStep}
          currentMessage=""
          setActiveStep={setActiveStep}
          methods={methods}
          handleNext={handleNext}
          steps={steps}
          loading={loading}
          ref={childRef}
          lastStepButtonLabel="Generate"
        />
      )}
    </Box>
  );
};
