import { WidgetWithData } from '@vyce/core/src/modules';
import { WidgetModuleProvider } from '@vyce/core/src/contexts';
import { WidgetItem } from '@vyce/core/src/types';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { useWidgetData } from './hooks';
import { useMemo } from 'react';

interface Props {
  widget: WidgetItem;
  redirectUrl?: string;
}

export const WidgetModule = ({ widget, redirectUrl, ...rest }: Props & WidgetWrapperProps) => {
  const data = useWidgetData();

  const contextOptions = useMemo(() => ({ ...data, widget, redirectUrl }), [data, widget, redirectUrl]);

  return (
    <WidgetModuleProvider value={contextOptions}>
      <WidgetWrapper {...rest}>
        <WidgetWithData />
      </WidgetWrapper>
    </WidgetModuleProvider>
  );
};
