import React from 'react';

import { Box } from '@material-ui/core';

import { PieChartDataItem } from '@vyce/core/src/types';

import { useStyles } from '../styles';
import { LegendItem } from './LegendItem';

interface Props {
  data: PieChartDataItem;
}

export const PieChartTooltip = ({ data }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.popup} minWidth={220}>
      <LegendItem data={data} />
    </Box>
  );
};
