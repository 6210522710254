import { createContext } from 'react';

import { TimeAndAttendanceLocation, Image } from '@vyce/core/src/types';
import { WorkerLookupDTO } from '@vyce/core/src/api/types';

import { Action, TransformedShift } from '../types';

interface Context {
  action: Action;
  locations?: TimeAndAttendanceLocation[];
  withPhoto?: boolean;
  loading?: boolean;
  image?: Image;
  location?: string;
  verifiedLocation?: boolean;
  timestamp: Date | 'now' | null;
  workers?: WorkerLookupDTO[];
  shifts?: TransformedShift[];
  nextStep: (jumpTo?: number) => void;
  setDialogOpen: () => void;
  onChangePhotoFlag: (photoFlag: boolean) => void;
  onChangeImage: (newImage: Image) => void;
  onChangeDate: (date: Date | 'now') => void;
  onChangeAction: (action: Action) => void;
  onChangeVerifiedLocation: (verified: boolean) => void;
}

export const ManualClockContext = createContext({} as Context);

export const ManualClockProvider = ManualClockContext.Provider;
