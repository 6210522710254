import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AxiosResponse } from 'axios';
import { Controller, useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';

import {
  Image,
  PersonalForm,
  UpdateUser,
  UploadAvatarData,
  User,
  VerifyPhoneData,
} from '@vyce/core/src/types';
import { AppKeyboardDatePicker, AppTextField } from '@vyce/core/src/components/inputs';
import {
  CSCSRegistrationNumberField,
  FirstNameField,
  GenderField,
  LastNameField,
  LocationField,
  NationalityField,
  NiNumberField,
} from '@vyce/core/src/components/controlled-inputs';
import { AppFormWrapper, ChangePasswordField, ReCAPTCHAComponent } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { formatDate } from '@vyce/core/src/utils/dates';
import { getLocationDetails } from '@vyce/core/src/utils/location';
import { checkNINumber } from '@vyce/core/src/utils/onboarding';
import { ChangePasswordData } from '@vyce/core/src/api/types';
import { capitalizeFirstLetter } from '@vyce/core/src/utils';
import { useRecaptcha } from '@vyce/core/src/hooks';

import { ProfileAvatarUploader } from '../components/ProfileAvatarUploader';
import { EditEmailOrVerify } from '../components/EditEmailOrVerify';
import { EditPhoneOrVerify } from '../components/EditPhoneOrVerify';
import useStyles from '../styles';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

const maxDate = new Date().setFullYear(new Date().getFullYear() - 16);
const emptyRules = {};

interface Props {
  uploadUserAvatar: ({ file, saveToProfile, handleServerError }: UploadAvatarData) => void;
  user: User;
  token: string;
  userDataLoading?: boolean;
  isLegend?: boolean;
  updateUser: (data: UpdateUser) => void;
  verifyPhone?: (data: VerifyPhoneData) => void;
  changePasswordRequest: (
    token: string,
    data: ChangePasswordData,
    userId?: string
  ) => Promise<AxiosResponse>;
}

export const UserPersonalInfo: React.FC<Props> = ({
  uploadUserAvatar,
  user,
  token,
  userDataLoading = false,
  isLegend,
  updateUser,
  verifyPhone,
  changePasswordRequest,
}) => {
  const classes = useStyles();
  const { showNotification, handleServerError } = useContext(NotificationContext);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [sensitiveData, setSensitiveData] = useState<string[]>([]);
  const [showAvatarButtons, setShowAvatarButtons] = useState<boolean>(false);
  const { reCaptchaRef, getReCaptchaToken } = useRecaptcha();

  const methods = useForm<PersonalForm & { avatar?: Image; gender?: { value: string; name: string } }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone || undefined,
      email: user.email || undefined,
      gender: user.gender ? { value: user.gender, name: capitalizeFirstLetter(user.gender) } : undefined,
      date_of_birth: user.date_of_birth || undefined,
      address: user.address || {
        address_snippet: undefined,
        address_line_4: undefined,
        address_line_2: undefined,
        address_line_1: undefined,
        address_line_3: undefined,
      },
      nationality: user.nationality || undefined,
      ni_number: user.context?.ni_number || undefined,
      cscs_ref_number: user.context?.cscs_ref_number || undefined,
      share_code: user.context?.share_code || undefined,
      avatar: { file: null, url: user.photo?.url },
    },
  });
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const fullAddress = watch('address.address_snippet');
  const avatar = watch('avatar');
  const { isMobile } = useContext(DeviceContext);

  const setAvatar = useCallback(
    (newAvatar: Image) => {
      setValue('avatar', newAvatar);
    },
    [setValue]
  );

  const checkAddress = async (fullAddress: string) => {
    const { address_line_4, address_line_2, address_line_3, address_line_1 } = await getLocationDetails({
      fullAddress,
    });
    setValue('address.address_line_2', address_line_2 || '');
    setValue('address.address_line_1', address_line_1 || '');
    setValue('address.address_line_3', address_line_3 || '');
    setValue('address.address_line_4', address_line_4 || '');
  };

  const handleSubmit = async ({ avatar, ...data }: PersonalForm & { avatar?: Image }) => {
    const sensitiveItems = [];
    if (data.ni_number && data.ni_number !== user.context?.ni_number) {
      sensitiveItems.push('NI Number');
    }
    if (data.last_name !== user.last_name) {
      sensitiveItems.push('Last Name');
    }
    if (data.date_of_birth !== undefined && data.date_of_birth !== user.date_of_birth) {
      sensitiveItems.push('Date of Birth');
    }
    setSensitiveData(sensitiveItems);

    if (avatar?.file) {
      await uploadUserAvatar({ file: avatar.file, saveToProfile: true, handleServerError });
      setShowAvatarButtons(false);
    }

    if (sensitiveItems?.length) {
      setOpenWarningDialog(true);
    } else {
      update(data);
    }
  };

  const update = async (data: PersonalForm) => {
    setOpenWarningDialog(false);
    const payloadData = await prepareUserData(data);
    if (data.ni_number && data.ni_number !== user.context?.ni_number) {
      const isNINumberAvailable = await checkNINumber(data.ni_number, showNotification);
      if (!isNINumberAvailable) {
        return;
      }
    }
    updateUser({ data: payloadData, showNotification, handleServerError });
  };

  const prepareUserData = async (data: PersonalForm): Promise<Partial<User>> => {
    const address = await getLocationDetails({
      fullAddress: data.address.address_snippet as string,
      houseNumber: data.address.address_line_1,
      street: data.address.address_line_2,
      town: data.address.address_line_3,
      postCode: data.address.address_line_4,
    });
    const userData: Partial<User> = {
      first_name: data.first_name,
      last_name: data.last_name,
      nationality: data.nationality,
    };
    if (address.address_snippet) {
      userData.address = address;
    }
    if (data.date_of_birth) {
      userData.date_of_birth = formatDate(data.date_of_birth);
    }
    if (data.ni_number || data.cscs_ref_number) {
      userData.context = {
        ni_number: data.ni_number,
        cscs_ref_number: data.cscs_ref_number,
      };
    }

    userData.gender = data.gender?.value || null;

    return userData;
  };

  useEffect(() => {
    if (fullAddress && user?.address?.address_snippet !== fullAddress) {
      checkAddress(fullAddress);
    }
  }, [fullAddress]);

  return (
    <Box paddingBottom={10}>
      <AppFormWrapper
        methods={methods}
        initialData={user}
        prePopulateValues={false}
        handleSubmit={handleSubmit}
        loading={userDataLoading}>
        <Grid container spacing={3} className={classes.infoContainer}>
          <Grid item xs={12} lg={3}>
            <ProfileAvatarUploader
              avatar={avatar}
              oldAvatarUrl={user.photo?.url}
              setAvatar={setAvatar}
              title={`${user.first_name} ${user.last_name}`}
              loading={userDataLoading}
              showAvatarButtons={showAvatarButtons}
              setShowAvatarButtons={setShowAvatarButtons}
            />
          </Grid>

          <Grid item xs={12} lg={9}>
            <Paper className={classes.formPaper} variant="outlined">
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <EditEmailOrVerify
                    isLegend={isLegend}
                    email={user.email}
                    userId={user.uuid}
                    verified={!!user.email_verified}
                    getReCaptchaToken={getReCaptchaToken}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <EditPhoneOrVerify
                    isLegend={isLegend}
                    phone={user.phone}
                    userId={user.uuid}
                    verified={!!user.phone_verified}
                    verifyPhone={verifyPhone}
                    getReCaptchaToken={getReCaptchaToken}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <FirstNameField />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <LastNameField />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      name="date_of_birth"
                      control={control}
                      render={props => (
                        <AppKeyboardDatePicker
                          value={props.field.value}
                          onChange={data => props.field.onChange(formatDate(data))}
                          label="Date of Birth"
                          format="dd/MM/yyyy"
                          error={undefined}
                          fullWidth
                          disableFuture
                          maxDate={maxDate}
                          minDateMessage="Please add your actual date of birth"
                          maxDateMessage="Please add your actual date of birth"
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <NationalityField rules={emptyRules} />
                </Grid>

                <Grid item xs={12}>
                  <LocationField rules={emptyRules} name="address.address_snippet" />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Controller
                    control={control}
                    rules={{ required: 'Postcode is required' }}
                    name="address.address_line_4"
                    render={({ field }) => (
                      <AppTextField
                        {...field}
                        value={field.value || ''}
                        id="postCode"
                        label="Postcode"
                        error={!!errors?.address?.address_line_4?.message}
                        helperText={errors?.address?.address_line_4?.message || ''}
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Controller
                    control={control}
                    rules={{ required: 'Street is required' }}
                    name="address.address_line_2"
                    render={({ field }) => (
                      <AppTextField
                        {...field}
                        value={field.value || ''}
                        id="street"
                        label="Street"
                        error={!!errors?.address?.address_line_2?.message}
                        helperText={errors?.address?.address_line_2?.message || ''}
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Controller
                    control={control}
                    rules={{ required: 'House Number is required' }}
                    name="address.address_line_1"
                    render={({ field }) => (
                      <AppTextField
                        {...field}
                        error={!!errors?.address?.address_line_1?.message}
                        helperText={errors?.address?.address_line_1?.message || ''}
                        value={field.value || ''}
                        id="house_number"
                        label="House Number"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Controller
                    control={control}
                    name="address.address_line_3"
                    render={({ field }) => (
                      <AppTextField
                        error={!!errors?.address?.address_line_3?.message}
                        helperText={errors?.address?.address_line_3?.message || ''}
                        {...field}
                        value={field.value || ''}
                        id="town"
                        label="Town"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <NiNumberField />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <CSCSRegistrationNumberField name="cscs_ref_number" />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <GenderField />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </AppFormWrapper>

      <Grid container spacing={2} style={{ marginTop: 16 }}>
        <Grid item xs={12} lg={9}>
          {user.email && (
            <Paper className={classes.formPaper} variant="outlined">
              <Typography variant="h6">Account Details</Typography>

              <Box marginTop={2}>
                <ChangePasswordField
                  user={user}
                  isLegend={isLegend}
                  changePasswordRequest={changePasswordRequest}
                  token={token}
                  email={user.email}
                />
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>

      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        open={openWarningDialog}
        onClose={() => setOpenWarningDialog(false)}>
        <DialogTitle>You may lose data. Are you sure?</DialogTitle>
        <DialogContent>
          If you change {sensitiveData?.join(', ')}, we will recheck all you qualifications.
        </DialogContent>
        <DialogActions>
          <Button size="large" variant="outlined" onClick={() => setOpenWarningDialog(false)}>
            Cancel
          </Button>
          <Button size="large" onClick={() => update(getValues())} color="primary" variant="contained">
            Ok, Update
          </Button>
        </DialogActions>
      </Dialog>
      <ReCAPTCHAComponent ref={reCaptchaRef} />
    </Box>
  );
};
