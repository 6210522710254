import React, { useContext, useEffect, useMemo, useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { Controller, useFormContext } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

import { Company, FieldProps } from '../../types';
import { searchCompanies } from '../../api/companies-house';
import { AppTextField } from '../inputs';
import { DeviceContext } from '../../contexts';
import { appAutocompleteUseStyles } from './ControlledAutocomplete';

const companyFieldRules = {
  validate: (value?: string[]) => {
    if (value === undefined) {
      return true;
    }
    return value?.length <= 5 || 'You can select only 5 companies';
  },
};

export const CompaniesField: React.FC<FieldProps> = ({
  margin = 'normal',
  label = 'Company',
  name = 'companies',
  rules = companyFieldRules,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Company[]>([]);
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const autocompleteClasses = appAutocompleteUseStyles({ withoutLabel: true });
  const { isMobile } = useContext(DeviceContext);

  const debouncedChangeHandler = useMemo(() => debounce(query => fetchCompanies(query), 300), []);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const fetchCompanies = async (search: string) => {
    clearSearch();
    setLoading(true);
    try {
      const response = await searchCompanies(search);
      const newOptions = response.data?.data;
      setOptions(newOptions);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleSearchChange = (search: string) => {
    debouncedChangeHandler(search);
  };

  const clearSearch = () => {
    setOptions([]);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={props => (
        <Autocomplete
          value={props.field.value || null}
          onChange={(e, data) => {
            props.field.onChange(data);
            setOptions([]);
          }}
          id={name}
          fullWidth
          disablePortal={isMobile}
          popupIcon={<FiChevronDown size="25px" color="#A0A3BD" />}
          closeIcon={<IoClose size="25px" color="#A0A3BD" />}
          classes={{ ...autocompleteClasses }}
          freeSolo={true}
          clearOnEscape
          filterOptions={options => options}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name}
          options={options}
          loading={loading}
          renderInput={params => (
            <AppTextField
              {...params}
              label={label}
              margin={margin}
              onBlur={clearSearch}
              onChange={e => {
                const search = e.target.value;
                const company = {
                  name: search.toUpperCase(),
                };
                props.field.onChange(company);
                handleSearchChange(search);
              }}
              fullWidth
              error={errors && !!errors[name]?.message}
              helperText={((errors && errors[name]?.message) as string) || ''}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="primary" style={{ marginBottom: '20px' }} size="16px" />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};
