import React from 'react';

import { SizeMe, SizeMeProps } from 'react-sizeme';
import { Layouts, Responsive as ResponsiveReactGridLayout } from 'react-grid-layout';
import { Box, useTheme } from '@material-ui/core';

import { WidgetItem } from '../../types';

interface Props {
  widgets: WidgetItem[];
  getWidget: (item: WidgetItem) =>
    | {
        component: (props: any) => JSX.Element;
        props: any;
      }
    | undefined;
  onWidgetStatusChange: Function;
  onLayoutChange: any;
  layouts: any;
  isWidgetsClosable?: boolean;
}

const margins: [number, number] = [24, 24];
const cols = { lg: 8, md: 4, sm: 2, xs: 1, xxs: 1 };

export const getInitialLayouts = (items: WidgetItem[]): any => ({
  lg: items.map(item => item.layout),
  md: items.map(item => item.layout),
  sm: items.map(item => item.layout),
  xxs: items.map(item => item.layout),
  xs: items.map(item => item.layout),
});

const formatLayouts = (layouts: any) => {
  if (!layouts) {
    return undefined;
  }

  let formattedLayout: Layouts = {};
  Object.keys(layouts)?.forEach(key => {
    formattedLayout[key] = layouts[key]
      ? layouts[key]?.map((item: any) => ({
          ...item,
          minH: item.minH || undefined,
          minW: item.minW || undefined,
          maxH: item.maxH || undefined,
          maxW: item.maxW || undefined,
          w: item.minW && item.minW > item.w ? item.minW : item.w,
        }))
      : [];
  });
  return formattedLayout;
};

export const AppDashboard: React.FC<Props> = ({
  widgets,
  layouts,
  getWidget,
  onWidgetStatusChange,
  onLayoutChange,
  isWidgetsClosable = true,
}) => {
  const theme = useTheme();
  const { lg, md, xs, sm } = theme.breakpoints.values;
  const formattedLayouts = React.useMemo(() => formatLayouts(layouts), [layouts]);
  const children = React.useMemo(() => {
    return widgets
      ?.filter(item => item.is_active)
      ?.map(item => {
        const { component, props } = getWidget(item) ?? {};
        return component ? (
          <Box key={item.system_name} className="widget" overflow="auto" data-grid={item.layout}>
            {React.createElement(component, {
              closable: isWidgetsClosable,
              onRemoveItem: onWidgetStatusChange,
              system_name: item.system_name,
              name: item.name,
              isResizable: item.layout?.isResizable,
              title_color: item.title_color,
              ...props,
            })}
          </Box>
        ) : null;
      });
  }, [widgets, getWidget]);

  return (
    <SizeMe>
      {(props: SizeMeProps) => (
        <ResponsiveReactGridLayout
          className="layout"
          layouts={formattedLayouts}
          isBounded={true}
          margin={margins}
          breakpoints={{ lg, md, sm, xs, xxs: xs }}
          cols={cols}
          rowHeight={40}
          useCSSTransforms={true}
          draggableHandle=".draggable-widget-header"
          onLayoutChange={onLayoutChange}
          width={props.size.width || 0}>
          {children}
        </ResponsiveReactGridLayout>
      )}
    </SizeMe>
  );
};
