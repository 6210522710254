import circleToPolygon from 'circle-to-polygon';
import uniqBy from 'lodash/uniqBy';
import * as GeoJSON from 'geojson';
import { Position } from 'geojson';
import { LatLngExpression, LatLngLiteral } from 'leaflet';

import {
  BasicSite,
  MapMarker,
  Shift,
  ShiftScheduleDetails,
  Site,
  SiteDaySchedule,
  SiteShift,
  SiteShiftSchedule,
  TimeAndAttendanceLocation,
  TrackEvent,
  TrackEventType,
  TrackingType,
  TrackingTypeOption,
} from '../../types';
import markerRed from '../../assets/svg/marker-red.svg';
import markerGreen from '../../assets/svg/marker-green.svg';
import markerDot from '../../assets/svg/marker-dot.svg';

import { SITE_RADIUS, TRACKING_TYPES } from './constants';
import { getTime } from '../../utils/dates';
import { CustomLocationField } from '../../api/types';

export const convertPolygonToString = (coordinates: Position[]): string => {
  let str = '';

  coordinates.forEach(item => {
    if (!str) {
      str = `${item[0]} ${item[1]}`;
    } else {
      str = `${str}, ${item[0]} ${item[1]}`;
    }
  });

  return `POLYGON((${str}))`;
};

export const convertCoordinateToString = (lat: number, lon: number): string => {
  return `POINT(${lon} ${lat})`;
};

export const shiftToSiteShift = (shift: Shift): SiteShift => {
  const schedule: SiteShiftSchedule = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  // @ts-ignore
  Object.keys(schedule).forEach((key: keyof SiteShiftSchedule) => {
    const regularShiftItem = shift.regular?.schedule[key];
    const regularStartTime = regularShiftItem.start.split(':');
    const regularEndTime = regularShiftItem.end.split(':');

    const overtimeShiftItem = shift.overtime?.schedule[key];
    const overtimeStartTime = overtimeShiftItem.start.split(':');
    const overtimeEndTime = overtimeShiftItem.end.split(':');

    schedule[key].push(
      {
        hour_type: 'basic',
        start: {
          hours: +regularStartTime[0] || 0,
          minutes: +regularStartTime[1] || 0,
        },
        end: {
          hours: +regularEndTime[0] || 0,
          minutes: +regularEndTime[1] || 0,
        },
      },
      {
        hour_type: 'overtime',
        start: {
          hours: +overtimeStartTime[0] || 0,
          minutes: +overtimeStartTime[1] || 0,
        },
        end: {
          hours: +overtimeEndTime[0] || 0,
          minutes: +overtimeEndTime[1] || 0,
        },
      }
    );
  });

  return {
    uuid: shift.uuid,
    name: shift.name,
    basic_unit: shift.basic_unit,
    basic_amount: shift.basic_amount || null,
    overtime_amount: shift.overtime_amount || null,
    overtime_unit: shift.overtime_unit,
    breaks: shift.breaks?.filter(item => item.name && item.minutes),
    schedule: schedule,
  };
};

export const siteShiftToShift = (siteShift: SiteShift): Shift => {
  const regular: ShiftScheduleDetails = {
    startTime: null,
    endTime: null,
    schedule: {
      monday: {
        start: '',
        end: '',
      },
      tuesday: {
        start: '',
        end: '',
      },
      wednesday: {
        start: '',
        end: '',
      },
      thursday: {
        start: '',
        end: '',
      },
      friday: {
        start: '',
        end: '',
      },
      saturday: {
        start: '',
        end: '',
      },
      sunday: {
        start: '',
        end: '',
      },
    },
  };
  const overtime: ShiftScheduleDetails = {
    startTime: null,
    endTime: null,
    schedule: {
      monday: {
        start: '',
        end: '',
      },
      tuesday: {
        start: '',
        end: '',
      },
      wednesday: {
        start: '',
        end: '',
      },
      thursday: {
        start: '',
        end: '',
      },
      friday: {
        start: '',
        end: '',
      },
      saturday: {
        start: '',
        end: '',
      },
      sunday: {
        start: '',
        end: '',
      },
    },
  };

  // @ts-ignore
  Object.keys(siteShift.schedule).forEach((key: keyof SiteShiftSchedule) => {
    const daySchedules: SiteDaySchedule[] = siteShift.schedule[key];

    daySchedules.forEach(schedule => {
      const startHours = schedule.start.hours;
      const startMinutes = schedule.start.minutes;
      const endHours = schedule.end.hours;
      const endMinutes = schedule.end.minutes;
      const isEmpty = !startHours && !startMinutes && !endHours && !endMinutes;

      if (schedule.hour_type === 'basic') {
        regular.schedule[key].start = isEmpty ? '' : numberTimeToString(startHours, startMinutes);
        regular.schedule[key].end = isEmpty ? '' : numberTimeToString(endHours, endMinutes);
      }

      if (schedule.hour_type === 'overtime') {
        overtime.schedule[key].start = isEmpty ? '' : numberTimeToString(startHours, startMinutes);
        overtime.schedule[key].end = isEmpty ? '' : numberTimeToString(endHours, endMinutes);
      }
    });
  });

  return {
    uuid: siteShift.uuid || siteShift.shift_id,
    name: siteShift.name,
    basic_unit: siteShift.basic_unit,
    basic_amount: siteShift.basic_amount,
    overtime_amount: siteShift.overtime_amount,
    overtime_unit: siteShift.overtime_unit,
    breaks: siteShift.breaks,
    regular,
    overtime,
  };
};

export const polygonStringToCoordinatesArray = (polygonString: string): Position[][] => {
  const coordinatesString = polygonString.replace('POLYGON ((', '').replace('))', '');
  return [coordinatesString.split(', ').map(coord => coord.split(' ').map(parseFloat))];
};

export const coordinatesToLatLng = (coordinates: Position[]): LatLngLiteral[] => {
  return coordinates.map(coord => ({ lat: coord[1], lng: coord[0] }));
};

export const siteToLocation = (site: Site): TimeAndAttendanceLocation => {
  let polygon: GeoJSON.Polygon | undefined;
  let center: LatLngLiteral | undefined;
  if (site.geo_polygon && !site.geo_point) {
    const coordinates = polygonStringToCoordinatesArray(site.geo_polygon);
    polygon = {
      coordinates,
      type: 'Polygon',
    };
    center = getPolygonCenter(coordinates[0]);
  } else if (site.geo_point) {
    const coords = pointToCoords(site.geo_point);
    center = { lat: +coords[1], lng: +coords[0] };
  }

  return {
    uuid: site.uuid,
    name: site.name,
    tracking_type: TRACKING_TYPES.find(type => type.value === site.tracking_type),
    address: {
      address_line_1: site.address_line_1 as string,
      address_line_2: site.address_line_2,
      address_line_3: site.address_line_3,
      address_line_4: site.address_line_4,
      address_snippet: site.address_snippet,
      country: site.country,
      lat: site.lat,
      lon: site.lon,
    },
    polygon,
    center,
    shifts: site.shifts
      ?.filter(shift => !shift.deleted_at)
      ?.map((shift: SiteShift) => siteShiftToShift(shift)),
  };
};

export const locationToSite = (location: TimeAndAttendanceLocation): BasicSite => {
  let geo_polygon = null;
  let geo_point = null;
  const { lat, lon } = location.address || {};

  if (location.polygon?.coordinates?.length) {
    geo_polygon = convertPolygonToString(location.polygon.coordinates[0]);
  } else if (lat && lon) {
    const polygonObj = circleToPolygon([lon, lat], SITE_RADIUS);
    geo_polygon = convertPolygonToString(polygonObj.coordinates[0]);
    geo_point = convertCoordinateToString(lat, lon);
  }

  return {
    name: location.name,
    address_line_1: location.address?.address_line_1 || '',
    address_line_2: location.address?.address_line_2,
    address_line_3: location.address?.address_line_3,
    address_line_4: location.address?.address_line_4,
    address_snippet: location.address?.address_snippet,
    country: location.address?.country,
    geo_polygon,
    geo_point,
    tracking_type: location.tracking_type?.value,
    area_related: !!location.address?.address_snippet,
  };
};

const numberTimeToString = (hours: number, minutes: number): string => {
  return `${hours > 9 ? hours.toString() : `0${hours}`}:${minutes > 9 ? minutes.toString() : `0${minutes}`}`;
};

const pointToCoords = (point: string): string[] => {
  return point.replace('POINT ', '').replace('(', '').replace(')', '').split(' ');
};

export const getActivityMarkers = (
  events: TrackEvent[],
  darkMode: boolean,
  trackingType?: TrackingTypeOption
): { markers: MapMarker[]; lineCoords: LatLngExpression[] } => {
  if (trackingType?.value === TrackingType.NO_TRACKING) {
    return {
      markers: [],
      lineCoords: [],
    };
  }

  const formattedEvents = events.map(event => ({
    ...event,
    uniqField: getTime(event.timestamp) + event.event_type,
  }));
  const markers: MapMarker[] = [];
  const lineCoords: LatLngExpression[] = [];

  uniqBy(formattedEvents, 'uniqField').forEach((item: TrackEvent) => {
    if (!item.geo_point) return;
    const coordinates = pointToCoords(item.geo_point);
    const lat = +coordinates[0];
    const lon = +coordinates[1];

    if (item.event_type === TrackEventType.CLOCK_IN) {
      markers.push({
        lat,
        lon,
        picture: markerGreen,
        name: 'Clock In',
        id: item.timestamp,
      });
    }

    if (item.event_type === TrackEventType.CLOCK_OUT) {
      markers.push({
        lat,
        lon,
        picture: markerRed,
        name: 'Clock Out',
        id: item.timestamp,
      });
    }

    if (trackingType?.value === TrackingType.BREADCRUMBS) {
      lineCoords.push([lat || 0, lon || 0]);
      markers.push({
        lat,
        lon,
        picture: markerDot,
        size: 8,
        name: getTime(item.timestamp),
        id: item.timestamp,
      });
    }
  });

  return {
    markers,
    lineCoords,
  };
};

export const getScheduleSetupStatus = (schedule: SiteShiftSchedule): boolean => {
  let status = false;
  Object.keys(schedule).forEach(key => {
    // @ts-ignore
    if (schedule[key]?.start && schedule[key]?.end) {
      status = true;
    }
  });
  return status;
};

const getPolygonCenter = (polygon: Position[]): LatLngLiteral => {
  let minX: number | null = null;
  let maxX: number | null = null;
  let minY: number | null = null;
  let maxY: number | null = null;

  for (let i = 0; i < polygon.length; i++) {
    minX = minX === null || polygon[i][0] < minX ? polygon[i][0] : minX;
    maxX = maxX === null || polygon[i][0] > maxX ? polygon[i][0] : maxX;
    minY = minY === null || polygon[i][1] < minY ? polygon[i][1] : minY;
    maxY = maxY === null || polygon[i][1] > maxY ? polygon[i][1] : maxY;
  }

  return { lng: (minX! + maxX!) / 2, lat: (minY! + maxY!) / 2 };
};

export const prepareCustomDetailsForSending = (data: Record<string, string>) => {
  return Object.keys(data).reduce((sum, current) => {
    sum.push({ system_name: current, value: data[current] });
    return sum;
  }, [] as Omit<CustomLocationField, 'name'>[]);
};
