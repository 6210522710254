import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';

import { Box, MenuItem, MenuList, Typography } from '@material-ui/core';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';

import { AppDataGrid } from '@vyce/core/src/components/AppDataGrid';
import { CIS300Submit, CISReport, ReportPayload } from '@vyce/core/src/types';
import { ConfirmDialog } from '@vyce/core/src/components/ConfirmDialog';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { GridActions } from '@vyce/core/src/components';
import { generateFileLinkAndSave } from '@vyce/core/src/utils';
import {
  DownloadCISReportRequest,
  RefreshCISReportRequest,
  SendCISReportRequest,
} from '@vyce/core/src/api/types';

import { StatusComponents } from './components';
import { getCis300SubmitsRequest } from '../../api/legend/pay';
import { CISSubmitsDialog } from './components/CISSubmitsDialog';

interface Props {
  sendCis300ToHMRCRequest: (params: SendCISReportRequest) => Promise<AxiosResponse>;
  getCisReportsRequest: (params: ReportPayload) => Promise<AxiosResponse>;
  downloadCISReportRequest: (params: DownloadCISReportRequest) => Promise<AxiosResponse>;
  refreshCISReportRequest?: (params: RefreshCISReportRequest) => Promise<AxiosResponse>;
  companyId: string;
  token: string;
  isLegend?: boolean;
}

const defaultSortModel: GridSortModel = [{ field: 'tax_year', sort: 'desc' }];

export const Reports: React.FC<Props> = ({
  isLegend = false,
  sendCis300ToHMRCRequest,
  getCisReportsRequest,
  downloadCISReportRequest,
  refreshCISReportRequest,
  companyId,
}) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [reports, setReports] = useState<CISReport[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedReport, setSelectedReport] = useState<CISReport>();
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openCISSubmitsDialog, setOpenCISSubmitsDialog] = useState<boolean>(false);
  const [submits, setSubmits] = useState<CIS300Submit[]>([]);
  const { sortModel, offset, total, setTotal, handleSortModelChange, handlePageChange } = useTable({
    defaultSortModel,
  });

  const initialColumns: GridColDef[] = [
    {
      field: 'tax_year',
      headerName: 'Tax Year',
      flex: 9,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'company',
      headerName: 'Company',
      valueGetter: params => params.row?.company?.name,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      flex: 6,
      minWidth: 120,
    },
    {
      field: 'tax_month',
      headerName: 'Tax Month',
      flex: 9,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: params => (
        <StatusComponents status={params.row.status} isGreen={params.row.status === 'accepted'} />
      ),
      flex: 9,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions close={closeGridAction}>
            <MenuList>
              {params.row.status !== 'submitted' && (
                <MenuItem onClick={() => handleSubmit(params.row as CISReport)}>Submit</MenuItem>
              )}
              <MenuItem onClick={() => handleDownload(params.row as CISReport)}>Download</MenuItem>
              {refreshCISReportRequest && (
                <MenuItem onClick={() => handleRefresh(params.row as CISReport)}>Refresh</MenuItem>
              )}
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  const columns = useMemo(
    () => (companyId ? initialColumns.filter(item => item.field !== 'company') : initialColumns),
    [closeGridAction, companyId]
  );

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const getCis300Submits = async (report: CISReport): Promise<CIS300Submit[]> => {
    try {
      const res = await getCis300SubmitsRequest({
        tax_year: report.tax_year,
        tax_month: report.tax_month,
        hmrc_settings_id: report.hmrc_settings_id,
      });
      return res.data.items;
    } catch (e) {
      handleServerError(e);
      return [];
    }
  };

  const handleSubmit = async (report: CISReport) => {
    setSelectedReport(report);
    resetCloseStatus();
    if (isLegend) {
      const submits = await getCis300Submits(report);
      if (submits.length > 0) {
        setSubmits(submits);
        setOpenCISSubmitsDialog(true);
        return;
      }
    }
    setOpenConfirmDialog(true);
  };

  const handleDownload = async (report?: CISReport) => {
    if (!report) {
      return;
    }
    try {
      const res = await downloadCISReportRequest({ companyId, reportId: report.uuid });
      generateFileLinkAndSave(res);
      resetCloseStatus();
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleRefresh = async (report: CISReport) => {
    if (!refreshCISReportRequest) {
      return;
    }

    try {
      const res = await refreshCISReportRequest({
        companyId: report.company.company_id,
        taxYear: report.tax_year,
        taxMonth: report.tax_month,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      resetCloseStatus();
    } catch (e) {
      handleServerError(e);
    }
  };

  const getReports = useCallback(async () => {
    try {
      const payload: ReportPayload = {
        limit: GRID_PAGE_SIZE,
        offset,
        order_by: formatSortModel<CISReport>(sortModel),
        company_id: companyId,
      };

      const res = await getCisReportsRequest(payload);
      setReports(res.data.items);
      setTotal(res.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setReports([]);
      handleServerError(e);
    }
  }, [companyId, offset, sortModel]);

  const sendToHMRC = async () => {
    if (!selectedReport) {
      return;
    }
    const id = companyId || selectedReport?.company?.company_id;
    try {
      setLoading(true);
      await sendCis300ToHMRCRequest({
        companyId: id,
        taxMonth: selectedReport.tax_month,
        taxYear: selectedReport.tax_year,
      });
      showNotification({ message: 'Report has been sent', options: { variant: 'success' } });
      setOpenConfirmDialog(false);
      setOpenCISSubmitsDialog(false);
      setSelectedReport(undefined);
      getReports();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  useEffect(() => {
    getReports();
  }, [getReports]);

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={{ fontWeight: 600 }} variant="h6">
          CIS 300
        </Typography>
      </Box>

      <AppDataGrid
        noPaper
        rows={reports}
        loading={loading}
        height="calc(100vh - 256px)"
        columns={columns}
        getRowId={row => row.uuid}
        pageSize={GRID_PAGE_SIZE}
        rowCount={total}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
      />

      <ConfirmDialog
        handleClose={() => setOpenConfirmDialog(false)}
        open={openConfirmDialog}
        confirmText="Submit"
        cancelText="Cancel"
        title="Submit CIS-300?"
        subtitle="Are you sure?"
        handleConfirm={() => sendToHMRC()}
      />

      <CISSubmitsDialog
        loading={loading}
        open={openCISSubmitsDialog}
        handleClose={() => setOpenCISSubmitsDialog(false)}
        submits={submits}
        submit={sendToHMRC}
        handleDownload={handleDownload}
        report={selectedReport}
      />
    </>
  );
};
