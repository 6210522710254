import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '../inputs';
import { FieldProps } from '../../types';

export const CSCSRegistrationNumberField: React.FC<FieldProps> = ({ margin, name = 'cscs_id' }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <AppTextField label="CSCS Registration number" margin={margin} fullWidth {...field} />
      )}
    />
  );
};
