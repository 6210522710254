import React from 'react';

import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme, Badge, MenuItem } from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import { IoMdMore } from 'react-icons/io';

import { AppIconButton } from '../AppIconButton';
import { MAIN_CONTAINER_ID } from '../../constants';
import { monochrome } from '../../theme/styles';

const StyledMenu = withStyles({
  paper: {
    border: `1px solid ${monochrome.mediumlight}`,
    backgroundColor: monochrome.lighter,
    padding: '8px',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      top: '3px',
      right: '3px',
      height: '16px',
      width: '16px',
      borderRadius: '50%',
    },
  })
)(Badge);

export const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gridGap: 8,
    },
  },
  buttonWrapper: {
    '&  button': {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      '& .MuiButton-label': {
        justifyContent: 'flex-end',
      },
    },
  },
}));

export const ExpandedMenuButton = ({
  hideBadge = true,
  children,
}: {
  hideBadge?: boolean;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledBadge color="primary" variant="dot" invisible={hideBadge}>
        <AppIconButton
          variant="paper"
          isBorder
          isSmall
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={handleClick}>
          <IoMdMore color={theme.palette.text.secondary} />
        </AppIconButton>
      </StyledBadge>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        onClose={handleClose}>
        <Box className={classes.buttonContainer}>
          {React.Children.map(children, child => {
            return (
              <Box onClick={handleClose} className={classes.buttonWrapper}>
                {child}
              </Box>
            );
          })}
        </Box>
      </StyledMenu>
    </div>
  );
};
