import React, { ChangeEvent, KeyboardEventHandler, useContext, useRef, useState } from 'react';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { FiChevronDown } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

import { AutocompleteProps, Tag } from '../../types';
import { createTagRequest } from '../../api/handbook';
import { AppTextField } from '../inputs';
import { AppChip } from '../AppChip';
import { DeviceContext } from '../../contexts';
import { appAutocompleteUseStyles } from './ControlledAutocomplete';

const filter = createFilterOptions<Tag>();
export const NEW_TAG_ID = '-1';

export const ControlledTagAutocomplete: React.FC<AutocompleteProps> = ({
  name,
  items = [],
  multiple,
  label,
  rules,
  disabled = false,
  disableClearable,
  defaultValue,
  margin,
  creatable = false,
  saveInDatabase = true,
  group = '',
  noOptionsText = 'No Options',
  manualMode = false,
  setSubstring,
}) => {
  const classes = appAutocompleteUseStyles({ withoutLabel: !label });
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { isMobile } = useContext(DeviceContext);
  const [search, setSearch] = useState('');

  const value = multiple ? [] : null;

  const createTag = async (name: string) => {
    try {
      const res = await createTagRequest({ name, group });
      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const errorMessage = errors[name] && errors[name]?.message;

  const deleteItem = (field: any, uuid: string) => {
    const updatedData = (field.value as Array<Tag>).filter(item => item.uuid !== uuid);
    field.onChange(updatedData);
  };

  const processMultipleChoiceTagCreation = async (data: Tag[]): Promise<Tag[]> => {
    const newTag = data.find((item: Tag) => item.uuid === NEW_TAG_ID);

    if (!newTag) {
      return data;
    }

    let values: Tag[];
    const tag = await createTag(newTag.name);
    if (tag) {
      values = data.map((item: Tag) => {
        return item.name.toUpperCase() === tag.name.toUpperCase() ? tag : item;
      });
    } else {
      values = data && data.filter((item: Tag) => item.uuid === NEW_TAG_ID);
    }
    return values;
  };

  const processSingleChoiceTagCreation = async (data: Tag): Promise<Tag> => {
    return createTag(data.name);
  };

  const handleChange = async (event: ChangeEvent<any> | null, value: any) => {
    let newValue = value;
    if (creatable && saveInDatabase) {
      const newTag = multiple ? value.find((item: Tag) => item.uuid === NEW_TAG_ID) : value;
      if (newTag?.uuid === NEW_TAG_ID) {
        newValue = multiple
          ? await processMultipleChoiceTagCreation(value)
          : await processSingleChoiceTagCreation(value);
      }
    }
    setSearch('');
    setValue(name, newValue);
  };

  const getFilterOptions = (options: any[], params: FilterOptionsState<any>) => {
    const filtered = filter(options, params);
    if (params.inputValue !== '' && filtered.length === 0 && creatable) {
      filtered.push({
        uuid: NEW_TAG_ID,
        label: `Add "${params.inputValue}"`,
        name: manualMode ? params.inputValue : startCase(toLower(params.inputValue)),
      });
    }

    return filtered;
  };

  const handleNewTagCreate = () => {
    if (search) {
      handleChange(null, {
        uuid: NEW_TAG_ID,
        name: manualMode ? search : startCase(toLower(search)),
      });
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleInputChange = (event: ChangeEvent<any> | null, value: string) => {
    setSubstring && setSubstring(value);
  };
  return (
    <Controller
      render={({ field }: any) => (
        <div className="multiple-autocomplete">
          <Autocomplete
            value={field.value || value}
            multiple={multiple}
            fullWidth
            onChange={handleChange}
            onInputChange={handleInputChange}
            onBlur={handleNewTagCreate}
            openOnFocus
            noOptionsText={noOptionsText}
            popupIcon={<FiChevronDown size="25px" color="#A0A3BD" />}
            closeIcon={<IoClose size="25px" color="#A0A3BD" />}
            disabled={disabled}
            defaultValue={defaultValue}
            disableClearable={disableClearable}
            disablePortal={isMobile}
            id={name}
            onKeyDown={handleKeyPress}
            classes={classes}
            options={items}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option;
              }
              return option.name;
            }}
            getOptionSelected={(option, value) =>
              typeof option === 'string' ? option === value : option.uuid === value.uuid
            }
            filterOptions={getFilterOptions}
            renderInput={params => (
              <AppTextField
                {...params}
                onChange={e => setSearch(e.target.value)}
                error={errors && !!errorMessage}
                margin={margin}
                autoComplete="off"
                label={label}
                inputProps={{
                  ...params.inputProps,
                }}
                helperText={((errors && errorMessage) as string) || ''}
              />
            )}
          />
          {multiple && (
            <Box marginTop="12px">
              {(field.value as Array<any>) &&
                (field.value as Array<any>).map(item => (
                  <AppChip
                    style={{ margin: '0 6px 6px 0' }}
                    key={item.uuid || item}
                    label={item.name || item}
                    onDelete={() => deleteItem(field, item.uuid)}
                  />
                ))}
            </Box>
          )}
        </div>
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
};
