import { useContext, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';

import { useBooleanState } from '@vyce/core/src/hooks';
import {
  getCompanyTeamsRequest,
  getTeamQualificationsRequest,
  getTeamsQualifications,
} from '@vyce/core/src/api/teams';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { TeamQualificationWidgetDTO } from '@vyce/core/src/api/types';

import { Props } from '../types';
import { defaultQualificationOption, defaultStatusOptions, defaultTeamOption, statuses } from '../config';

export const useWidgetData = ({ selectedCompanyId }: Props) => {
  const [teamQualifications, setTeamQualifications] = useState<TeamQualificationWidgetDTO[]>([]);
  const { handleServerError } = useContext(NotificationContext);
  const [teamsOptions, setTeamsOptions] = useState<any[]>([]);
  const [qualificationsOptions, setQualificationsOptions] = useState<(typeof defaultQualificationOption)[]>(
    []
  );
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(defaultTeamOption.value);
  const [selectedStatusId, setSelectedStatusId] = useState(
    defaultStatusOptions.find(item => item.value === statuses.Expired)?.value
  );

  const { offset, total, setTotal, handlePageChange } = useTable({});

  const methods = useForm<{ qualification: typeof defaultQualificationOption }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      qualification: defaultQualificationOption,
    },
  });

  const { watch } = methods;
  const qualification = watch('qualification');

  const teamsSelectOptions = useMemo(() => {
    if (!teamsOptions) return [defaultTeamOption];
    return [defaultTeamOption, ...teamsOptions];
  }, [teamsOptions]);

  const qualificationsSelectOptions = useMemo(() => {
    if (!qualificationsOptions) return [defaultQualificationOption];
    return [defaultQualificationOption, ...qualificationsOptions];
  }, [qualificationsOptions]);

  const getWidgetSelectorsData = async () => {
    if (!selectedCompanyId) {
      return;
    }

    try {
      const teamsData = await getCompanyTeamsRequest({
        companyId: selectedCompanyId,
        requestData: { order_by: [{ field_name: 'name', desc: false }] },
      });
      const qualificationsData = await getTeamQualificationsRequest(selectedCompanyId);
      setQualificationsOptions(qualificationsData.data.items.map(item => ({ value: item, name: item })));
      setTeamsOptions(teamsData.data.items.map(team => ({ value: team.uuid, name: team.name })));
    } catch (e) {
      handleServerError(e);
    }
  };

  const getTeamQualifications = async () => {
    if (!selectedCompanyId) {
      return;
    }

    setLoadingTrue();
    try {
      const res = await getTeamsQualifications({
        companyId: selectedCompanyId,
        data: {
          limit: 20,
          offset,
          team_id: selectedTeamId !== 'all' ? selectedTeamId : undefined,
          qualification: qualification && qualification?.value !== 'all' ? qualification.value : undefined,
          status: selectedStatusId !== 'all' ? selectedStatusId : undefined,
        },
      });
      setTotal(res.data.count);
      setTeamQualifications(res.data.items);
    } catch (e) {
      handleServerError(e);
    } finally {
      setLoadingFalse();
    }
  };

  useEffect(() => {
    getTeamQualifications();
  }, [selectedCompanyId, selectedTeamId, qualification, selectedStatusId, offset]);

  useEffect(() => {
    getWidgetSelectorsData();
  }, [selectedCompanyId]);

  return {
    teamQualifications,
    total,
    selectedTeamId,
    loading,
    teamsSelectOptions,
    qualificationsSelectOptions,
    selectedStatusId,
    methods,
    handlePageChange,
    setSelectedTeamId,
    setSelectedStatusId,
  };
};
