import React, { useContext, useEffect, useMemo, useState } from 'react';

import { ComputedDatum, ResponsivePie } from '@nivo/pie';
import { Box, Typography, useTheme, makeStyles, Theme } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { ColorThemeContext } from '@vyce/core/src/contexts/colorThemeContext';
import { PieChartDataItem } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { NoRowsOverlayComponent } from '@vyce/core/src/components';

import { CHART_COLORS, DARK_CHART_COLORS } from '../styles';
import { PieLegend } from './PieLegend';
import { PieChartTooltip } from './PieChartTooltip';
import { maxCountOfLegend } from '../config';
import { ArrowBox } from './ArrowBox';

const useStyles = makeStyles((theme: Theme) => ({
  pieContainer: {
    minHeight: '75px',
    minWidth: '75px',
    maxWidth: '205px',
    maxHeight: '205px',
    '& > div > div': {
      position: 'absolute !important',
    },
  },
}));

interface Props {
  data: PieChartDataItem[];
  extraLegendData?: PieChartDataItem | null;
  withPercentage?: boolean;
  handleChartClick?: (datum: ComputedDatum<PieChartDataItem>, event: any) => void;
}

const extraLegendDataStyles = { transform: 'translate(-50%, -50%)' };
const cursorStyles = { cursor: 'pointer' };

export const PieChart = ({ data, extraLegendData, withPercentage = true, handleChartClick }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { colorTheme } = useContext(ColorThemeContext);
  const { isMobile } = useContext(DeviceContext);
  const [page, setPage] = useState(0);
  const [chartData, setChartData] = useState<PieChartDataItem[]>([]);
  const [legendData, setLegendData] = useState<PieChartDataItem[]>([]);
  const isDarkMode = colorTheme === 'dark';
  const maxPage = useMemo(() => Math.ceil(chartData.length / maxCountOfLegend), [chartData]);

  const colors = useMemo(() => {
    const colorItems = isDarkMode ? DARK_CHART_COLORS : CHART_COLORS;
    return range(5).flatMap(() => colorItems);
  }, [isDarkMode]);

  const getPercentage = (sum: number, value: number): string => {
    return ((value / sum) * 100).toFixed();
  };

  const decreasePage = () => setPage(page - 1);
  const increasePage = () => setPage(page + 1);

  useEffect(() => {
    if (!data) {
      return;
    }
    const sortedData = orderBy(data, ['value'], ['desc']);
    const sum = sortedData.reduce((acc, item) => acc + Number(item.value), 0);
    const dataWithColors: PieChartDataItem[] = sortedData.map((item: any, index: number) => ({
      ...item,
      color: colors[index],
      percent: withPercentage ? getPercentage(sum, item.value) : null,
    }));

    setChartData(dataWithColors);
    setPage(0);
  }, [data, colors]);

  useEffect(
    () => setLegendData(chartData.slice(page * maxCountOfLegend, (page + 1) * maxCountOfLegend)),
    [chartData, page]
  );

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center">
      {data?.length ? (
        <Box
          display="flex"
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
          gridGap={16}
          style={cursorStyles}
          flexWrap="wrap">
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            position="relative"
            className={classes.pieContainer}>
            <ResponsivePie
              data={chartData}
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              valueFormat=" >-"
              sortByValue={true}
              innerRadius={0.85}
              padAngle={2}
              cornerRadius={10}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              onClick={handleChartClick}
              activeOuterRadiusOffset={2}
              activeInnerRadiusOffset={2}
              colors={chartData.map(item => item.color as string)}
              borderColor={{ theme: 'background' }}
              arcLinkLabelsDiagonalLength={7}
              arcLinkLabelsStraightLength={7}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor={theme.palette.text.secondary}
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              tooltip={({ datum: e }) => <PieChartTooltip data={e.data} />}
              animate={false}
            />
            {extraLegendData && (
              <Box
                display="flex"
                flexDirection="column"
                position="absolute"
                alignItems="center"
                top="50%"
                left="50%"
                style={extraLegendDataStyles}>
                <Typography variant="h5" color="textSecondary" style={{ fontWeight: 600 }}>
                  {extraLegendData.value}
                </Typography>
                <Typography variant="h6" color="textSecondary" style={{ fontWeight: 500 }}>
                  {extraLegendData.label}
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gridGap={isMobile ? 10 : 32}
            flex={0}
            minWidth="250px"
            minHeight="110px"
            maxWidth="290px"
            width="100%">
            <ArrowBox>
              {page !== 0 && (
                <FiChevronLeft color={theme.palette.primary.main} size="30px" onClick={decreasePage} />
              )}
            </ArrowBox>
            <Box maxWidth={200}>
              <PieLegend legendData={legendData} />
            </Box>
            <ArrowBox>
              {page < maxPage - 1 && (
                <FiChevronRight color={theme.palette.primary.main} size="30px" onClick={increasePage} />
              )}
            </ArrowBox>
          </Box>

          {!!legendData?.length && <Box marginTop={3} flex={0}></Box>}
        </Box>
      ) : (
        <NoRowsOverlayComponent />
      )}
    </Box>
  );
};
