import { axios } from '@vyce/core/src/api';

import { CreateUserData } from '../types';
import { User } from '../../types';

export const createUserRequest = (data: CreateUserData, recaptcha_token: string) => {
  return axios.post('/users', { ...data, recaptcha_token });
};

export const userMeRequest = (token: string) => {
  return axios.get(`/users/me`);
};

export const userExistsRequest = (email?: string, phone?: string) => {
  return axios.post(`/users/exists`, { email, phone });
};

export const updateMeRequest = (token: string, uuid: string, data: Partial<User>) => {
  return axios.patch(`/users/${uuid}`, data);
};

export const checkIfAvailableRequest = (data: { ni_number?: string; phone?: string; email?: string }) => {
  return axios.post(`/users/if_available`, data);
};

export const saveCSCSNumberRequest = (number: string) => {
  return axios.put(`/users/save_cscs_ref_number`, {
    serial_number: number,
  });
};
