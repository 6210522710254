import React, { KeyboardEvent, useContext, useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { Box, Tooltip, Typography, CircularProgress } from '@material-ui/core';
import { FiChevronDown } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import uniqBy from 'lodash/uniqBy';

import { User } from '@vyce/core/src/types';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { appAutocompleteUseStyles } from '@vyce/core/src/components/controlled-inputs/ControlledAutocomplete';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppChip } from '@vyce/core/src/components/AppChip';
import { fetchUsersRequest } from '@vyce/core/src/api/legend/users';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

export interface AutocompleteUser {
  email?: string;
  phone?: string;
  first_name?: string;
  last_name?: string;
  uuid: string;
}

interface Props {
  selectedUsers: AutocompleteUser[];
  setSelectedUsers: Function;
  label: string;
  mode: 'phone' | 'email';
  onlyUsers?: boolean;
  disabled?: boolean;
}

export const NOT_REGISTERED_USER_ID = 'not_registered_user';
const separators = [';', ',', ' '];

export const SearchUserAutocomplete: React.FC<Props> = ({
  selectedUsers,
  setSelectedUsers,
  mode,
  label,
  onlyUsers,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<AutocompleteUser[]>([]);
  const classes = appAutocompleteUseStyles({ withoutLabel: !label });
  const { isMobile } = useContext(DeviceContext);
  const { handleServerError } = useContext(NotificationContext);

  const onSearch = async (search: string) => {
    try {
      setLoading(true);
      const res = await fetchUsersRequest({ substring: search, limit: 50 });
      setUsers(res.data.items);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onlyUsers) {
        return;
      }
      const inputValue = (e.target as any).value;
      onSelect(inputValue);
      setOpen(false);
    }
  };

  const getNonRegisteredUser = (inputValue: string): AutocompleteUser => {
    const item: AutocompleteUser = {
      uuid: NOT_REGISTERED_USER_ID + inputValue,
      first_name: '',
      last_name: '',
    };
    if (mode === 'email') {
      item.email = inputValue;
    } else {
      item.phone = inputValue;
    }
    return item;
  };

  const onSelect = (value?: string) => {
    if (!value) {
      return;
    }
    const chars = value.split('');
    const users: AutocompleteUser[] = [];
    const separator = chars.find(char => separators.includes(char));
    if (separator) {
      const values = value.split(separator);
      values.forEach(item => users.push(getNonRegisteredUser(item)));
    } else {
      users.push(getNonRegisteredUser(value));
    }

    setSelectedUsers((value: AutocompleteUser[]) => uniqBy([...value, ...users], mode));
  };

  const onChange = (e: React.ChangeEvent<{}>, newValue: AutocompleteUser[]) => {
    const value = newValue[0];
    if (value.uuid.includes(NOT_REGISTERED_USER_ID)) {
      onSelect(mode === 'email' ? value.email : value.phone);
    } else {
      setSelectedUsers(newValue);
    }
  };

  useEffect(() => {
    if (!open) {
      setUsers([]);
    }
  }, [open]);

  useEffect(() => {
    onSearch('');
  }, [open]);

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        fullWidth
        multiple
        disabled={disabled}
        popupIcon={<FiChevronDown size="25px" color="#A0A3BD" />}
        closeIcon={<IoClose size="25px" color="#A0A3BD" />}
        filterSelectedOptions
        disablePortal={isMobile}
        noOptionsText="Not Found"
        classes={classes}
        value={selectedUsers}
        getOptionDisabled={option => mode === 'phone' && !option.phone}
        getOptionSelected={(option, value) => option.uuid === value.uuid}
        getOptionLabel={option => `${option.first_name} ${option.last_name}`}
        filterOptions={(options: AutocompleteUser[], params) => {
          if (onlyUsers) {
            return options;
          }
          const filtered = [...options];

          if (params.inputValue !== '' && filtered.length === 0) {
            const item = getNonRegisteredUser(params.inputValue);
            filtered.push(item);
          }
          return filtered;
        }}
        options={users}
        renderOption={user => (
          <Box display="flex" width="100%" alignItems="center">
            <Typography variant="subtitle2">
              {user.first_name} {user.last_name}
            </Typography>
            <Typography style={{ marginLeft: 8 }} variant="subtitle2" color="secondary">
              {mode === 'email' ? user.email : user.phone}
            </Typography>
          </Box>
        )}
        loading={loading}
        renderInput={params => (
          <AppTextField
            {...params}
            onChange={event => onSearch(event.target.value)}
            placeholder="Start typing"
            label={label}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <Box position="absolute" right={80} top={18}>
                      <CircularProgress color="secondary" size={20} />
                    </Box>
                  ) : (
                    <></>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <Box marginTop="12px">
        {selectedUsers.map(user => (
          <Tooltip title={user.email || user.phone || ''}>
            <AppChip
              style={{ margin: '0 6px 6px 0' }}
              key={user.uuid}
              label={
                user.first_name && user.last_name
                  ? `${user.first_name} ${user.last_name}`
                  : user.email || user.phone
              }
              onDelete={() =>
                setSelectedUsers((value: User[]) => value.filter(item => item.uuid !== user.uuid))
              }
            />
          </Tooltip>
        ))}
      </Box>
    </>
  );
};
