import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  FormControlLabel,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppCheckbox } from '@vyce/core/src/components/inputs/AppCheckbox';
import { ButtonTitleWithLoading } from '@vyce/core/src/components/ButtonTitleWithLoading';

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    height: '100%',
    overflow: 'auto',
  },
}));

const FilterBlock = ({
  title,
  type,
  options,
}: {
  title: string;
  type: string;
  options: { id: string; name: string }[];
}) => {
  const { control } = useFormContext();
  return (
    <Box display="flex" flexDirection="column" gridGap={8} overflow="auto">
      <Typography variant="subtitle2">{title}</Typography>
      {options.map(item => (
        <Controller
          key={item?.id}
          name={`${type}.${item?.id}`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <AppCheckbox
                  onChange={e => field.onChange(e.target.checked)}
                  color="primary"
                  variant="rectangle"
                  checked={field.value === undefined ? true : field.value}
                />
              }
              label={item?.name}
            />
          )}
        />
      ))}
    </Box>
  );
};

export const FiltersDialog = ({
  isOpen,
  loading,
  filterSettings,
  onSubmit,
  onClose,
  restoreFilters,
}: {
  isOpen: boolean;
  loading: boolean;
  filterSettings: {
    options: { id: string; name: string }[];
    title: string;
    type: string;
  }[];
  onClose: () => void;
  onSubmit: () => void;
  restoreFilters: () => void;
}) => {
  const { isMobile } = useContext(DeviceContext);
  const classes = useStyles();
  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={32}>
          <Typography>Didn't Clock Out - Filters</Typography>
          <Button variant="outlined" color="primary" onClick={restoreFilters}>
            Restore to default
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Box display="flex" gridGap={16} maxHeight="calc(100vh - 300px)" height="max-content" marginTop={2}>
          {filterSettings.map(item => (
            <Box key={item.type}>
              <FilterBlock key={item.type} title={item.title} options={item.options} type={item.type} />
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => onSubmit()}
          disabled={loading}>
          <ButtonTitleWithLoading title="Filter" loaderVariant="paper" loading={!!loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
