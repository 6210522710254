import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  useTheme,
} from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import { Autocomplete } from '@material-ui/lab';
import { FiChevronDown } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

import { Company, PaySchedule } from '@vyce/core/src/types';
import { AppSelect } from '@vyce/core/src/components/inputs/AppSelect';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { formatTableDate } from '@vyce/core/src/utils/dates';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { appAutocompleteUseStyles } from '@vyce/core/src/components/controlled-inputs/ControlledAutocomplete';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';

import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  paySchedules: PaySchedule[];
  companies?: Company[];
  selectedCompanyId?: string;
  getNextPayPeriod: (payScheduleId: string) => Promise<string>;
  generateNextPayrun: (payScheduleId: string, copyPrevious: boolean, isRedirect?: boolean) => void;
  handleCompanyChange?: (companyId: string) => void;
  open: boolean;
  setOpen: Function;
  handleDialogClose: Function;
}

export const CreateNewTimesheet: React.FC<Props> = ({
  paySchedules,
  getNextPayPeriod,
  generateNextPayrun,
  companies = [],
  handleCompanyChange,
  open,
  setOpen,
  selectedCompanyId,
  handleDialogClose,
}) => {
  const [payScheduleId, setPayScheduleId] = useState<string | undefined>(paySchedules[0]?.uuid);
  const [nextPayPeriod, setNextPayPeriod] = useState<any>();
  const { isMobile } = useContext(DeviceContext);
  const autocompleteClasses = appAutocompleteUseStyles({ withoutLabel: true });
  const theme = useTheme();

  const getPeriod = async (payScheduleId: string) => {
    const period = await getNextPayPeriod(payScheduleId);
    setNextPayPeriod(period);
  };

  useEffect(() => {
    if (payScheduleId) {
      getPeriod(payScheduleId);
    }
  }, [payScheduleId]);

  const close = () => {
    handleDialogClose();
    setTimeout(() => {
      setNextPayPeriod(undefined);
      setPayScheduleId(undefined);
    }, 200);
  };

  return (
    <>
      <Button
        startIcon={<AiOutlinePlus />}
        variant="contained"
        onClick={() => {
          setPayScheduleId(paySchedules[0]?.uuid);
          setOpen(true);
        }}
        size="small"
        cy-test-id="open-create-new-timesheet-dialog"
        color="primary">
        Create New Timesheet
      </Button>

      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        open={open}
        fullScreen={isMobile}
        onClose={close}>
        <DialogTitle>Create New Timesheet</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {handleCompanyChange && !!companies?.length && (
              <>
                <OnboardingStepTitle text="Which Company is this timesheet for?" />
                <Autocomplete
                  options={companies}
                  classes={autocompleteClasses}
                  onChange={(e: any, company: any) => {
                    setNextPayPeriod(undefined);
                    handleCompanyChange(company?.uuid);
                  }}
                  popupIcon={<FiChevronDown size="25px" color="#A0A3BD" />}
                  closeIcon={<IoClose size="25px" color="#A0A3BD" />}
                  getOptionLabel={(option: any) => option?.name}
                  fullWidth
                  renderInput={params => (
                    <AppTextField {...params} margin="normal" fullWidth label="Company" />
                  )}
                />
              </>
            )}

            {selectedCompanyId && (
              <>
                <OnboardingStepTitle text="Which pay schedule is this timesheet for?" />
                {!!paySchedules?.length ? (
                  <FormControl margin="normal" fullWidth variant="filled">
                    <InputLabel id="paySchedule">Pay Schedule</InputLabel>
                    <AppSelect
                      value={payScheduleId}
                      onChange={e => setPayScheduleId(e.target.value as string)}
                      labelId="paySchedule"
                      cy-test-id="pay-schedule-select"
                      fullWidth>
                      {paySchedules?.map(item => (
                        <MenuItem cy-test-id={`pay-schedule-${item.name}`} key={item.name} value={item.uuid}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </AppSelect>
                  </FormControl>
                ) : (
                  <Box marginTop={1}>
                    <Typography variant="subtitle2" style={{ color: theme.palette.warning.main }}>
                      Company has no Pay schedules
                    </Typography>
                  </Box>
                )}
              </>
            )}

            {selectedCompanyId && nextPayPeriod && (
              <Box marginTop={1}>
                <OnboardingStepTitle text="The next timesheet is for the following period..." />
                <Box marginTop={1}>
                  <Typography variant="caption" color="secondary" style={{ fontWeight: 600 }}>
                    Week: {nextPayPeriod.tax_week_end} - {formatTableDate(nextPayPeriod.start_date)} -{' '}
                    {formatTableDate(nextPayPeriod.end_date)} ({nextPayPeriod.pay_frequency})
                  </Typography>
                </Box>
              </Box>
            )}

            <Box marginTop={2}>
              <Typography variant="caption">
                All timesheets for previous periods have been created or submitted. To edit a previous
                period, click on that timesheet and click on the ‘Re-Open’ button.
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={close} variant="outlined">
            Cancel
          </Button>
          <Button
            size="large"
            color="primary"
            cy-test-id="create-new-timesheet"
            onClick={() => generateNextPayrun(payScheduleId as string, false, true)}
            variant="contained">
            Let's go
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
