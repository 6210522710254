import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { monochrome } from '../../theme/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      flexWrap: 'wrap',
    },

    heading: {
      width: '80%',
    },

    repositoriesWrapper: {
      width: '80%',
    },

    listItem: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: '1px solid black',

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'gainsboro',
      },
    },

    repositoryName: {
      padding: '0 1rem',
    },
    header: {
      padding: '16px 24px',
      display: 'flex',
      borderRadius: '8px 8px 0 0',
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.type === 'light' ? monochrome.lightest : monochrome.darkest,
      color: theme.palette.type === 'light' ? monochrome.mediumdark : monochrome.mediumlight,
      fontWeight: 500,
      fontSize: '13px',
      overflow: 'hidden',
    },
    row: {
      padding: '7px 24px',
      border: `1px solid ${theme.palette.divider}`,
      borderTop: 'none',
    },
    hint: {
      maxWidth: 300,
    },
    headerGrid: {
      width: '100%',
      overflow: 'hidden !important',
    },
    bodyGrid: {
      width: '100%',
    },

    leftSideGrid: {
      overflow: 'hidden !important',
    },

    rightSideGridContainer: {
      flex: '0 0 75px',
      zIndex: 10,
      position: 'absolute',
    },

    cell: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'center',
      padding: '0 .5em',
      width: '100%',
      height: '100%',
    },
    hiddenSortButton: {
      visibility: 'hidden',
    },

    columnItem: {
      '& > button': {
        width: '28px',
        height: '28px',
        borderRadius: '16px',
        backgroundColor: 'unset',
      },
      '&:hover': {
        '& > button': {
          visibility: 'unset',
          opacity: '1 !important',
          backgroundColor: theme.palette.background.default,
        },
      },
    },
  })
);
