import React from 'react';

import { ResponsiveLine } from '@nivo/line';
import { useTheme } from '@material-ui/core';

interface Props {
  data: any[];
}

export const TemperatureLineChart: React.FC<Props> = ({ data }) => {
  const theme = useTheme();

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 21, right: 43, bottom: 39, left: 43 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      pointLabelYOffset={-10}
      axisTop={null}
      axisRight={null}
      axisLeft={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 16,
        tickRotation: 0,
      }}
      enableGridX={false}
      enableGridY={false}
      colors={[theme.palette.primary.main]}
      pointSize={0}
      areaBlendMode="overlay"
      enableArea={true}
      enablePointLabel={true}
      pointLabel="data.y"
      useMesh={false}
      legends={[]}
      theme={{
        text: {
          fontSize: 13,
          fill: theme.palette.text.primary,
          outlineColor: theme.palette.text.primary,
        },
      }}
      animate={false}
    />
  );
};
