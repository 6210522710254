import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@material-ui/core';

import { ControlledSelect } from '@vyce/core/src/components/controlled-inputs/ControlledSelect';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { TimeAndAttendanceLocation } from '@vyce/core/src/types';

import { ManualClockContext } from './ManualClockProvider';
import { useContext } from 'react';

const rules = {
  required: 'Location is required',
};

const name = 'location';

const empty: TimeAndAttendanceLocation[] = [];

export const LocationStep = () => {
  const { action, shifts, locations, loading } = useContext(ManualClockContext);

  const { watch } = useFormContext();

  const value = watch(name);

  return (
    <>
      <OnboardingStepTitle
        text={`What location do you want to clock them ${action === 'in' ? 'into' : 'out of'}?`}
      />
      <ControlledSelect
        name={name}
        rules={rules}
        label="Select Location"
        margin="normal"
        items={locations || empty}
        multiple={false}
      />

      {value && shifts && !shifts.length && !loading && (
        <Typography color="error">There are no shifts assigned to this location.</Typography>
      )}
    </>
  );
};
