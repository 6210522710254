import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiCpu } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  Worker,
  WorkerProfessionalForm,
  Tag,
  RequestWorkerData,
  HelperState,
  TagGroups,
  Position,
  UpdateWorkerData,
} from '@vyce/core/src/types';
import { AppTextField } from '@vyce/core/src/components/inputs';
import { WorkExperienceCard } from '@vyce/core/src/components/WorkExperienceCard';
import { DeviceContext } from '@vyce/core/src/contexts';
import { SEARCH_STATUSES } from '@vyce/core/src/constants';
import { AppFormWrapper } from '@vyce/core/src/components';
import { getMonthsBetween, formatTimeRange } from '@vyce/core/src/utils';
import { jobRules, JobsField } from '@vyce/core/src/components/controlled-inputs/JobsField';
import { SpecificSkillsField } from '@vyce/core/src/components/controlled-inputs/SpecificSkillsField';
import { SearchStatusField } from '@vyce/core/src/components/controlled-inputs/SearchStatusField';

import { PositionDialog } from '../components/PositionDialog';
import { NotificationContext } from '../../../contexts/notificationContext';
import useStyles from '../styles';
import { AiBioGenerator } from './AiBioGenerator';

interface Props {
  updateWorker: ({ showNotification, data, handleServerError }: UpdateWorkerData) => void;
  helper: HelperState;
  worker?: Worker;
  jobTitle?: string;
  userCity?: string;
  userDataLoading?: boolean;
}

export const MAX_BIO_CHARACTERS = 2000;
const aiBioGeneratorQuery = 'ai-bio-generator';

export const getWorkerExperienceMonths = (positions: Position[]): number => {
  let months: number = 0;
  positions.forEach(position => {
    months += getMonthsBetween(position.start_date || new Date(), position.end_date || new Date());
  });
  return months;
};

export const getWorkerExperience = (positions: Position[]): string => {
  let months: number = getWorkerExperienceMonths(positions);
  return formatTimeRange(months);
};

export const WorkerProfessionalInfo: React.FC<Props> = ({
  updateWorker,
  helper,
  worker,
  jobTitle,
  userCity,
  userDataLoading,
}) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const classes = useStyles();
  const history = useHistory();
  const methods = useForm<WorkerProfessionalForm>({
    defaultValues: {
      biography: worker?.biography,
      roles: worker?.tags?.filter((tag: Tag) => tag.group === TagGroups.ROLE),
      skills: worker?.tags?.filter((tag: Tag) => tag.group === TagGroups.SKILL),
      search_status: SEARCH_STATUSES.find(status => status.group === worker?.search_status),
    },
  });
  const { access_token } = helper;
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = methods;
  const positions = worker?.positions || [];
  const [open, setOpen] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (data: WorkerProfessionalForm) => {
    const dataPayload = prepareWorkerData(data);
    updateWorker({ data: dataPayload, showNotification, handleServerError });
  };

  const prepareWorkerData = (data: WorkerProfessionalForm): RequestWorkerData => {
    const tags: Tag[] = [];
    if (data.roles) {
      tags.push(...data.roles);
    }
    if (data.skills) {
      tags.push(...data.skills);
    }

    return {
      tags,
      biography: data.biography,
      search_status: data.search_status?.group,
    };
  };

  const deletePosition = (positionIndex: number) => {
    updateWorker({
      data: { positions: positions.filter((position, index) => index !== positionIndex) },
      showNotification,
      handleServerError,
    });
  };

  const addPosition = (position: Position) => {
    updateWorker({ data: { positions: [...positions, position] }, showNotification, handleServerError });
  };

  const updatePosition = (positionIndex: number, updatedPosition: Position) => {
    updateWorker({
      data: {
        positions: positions.map((position, index) => {
          if (index === positionIndex) {
            return updatedPosition;
          }
          return position;
        }),
      },
      showNotification,
      handleServerError,
    });
  };

  const goToAIBioGenerator = () => {
    history.push(`${history.location.pathname}?${aiBioGeneratorQuery}`);
  };

  useEffect(() => {
    setValue('biography', worker?.biography || '');
  }, [worker?.biography, setValue]);

  return (
    <Box paddingBottom={10}>
      {history.location.search.includes(aiBioGeneratorQuery) ? (
        <Box>
          <AiBioGenerator updateWorker={updateWorker} jobTitle={jobTitle} city={userCity} worker={worker} />
        </Box>
      ) : (
        <>
          <AppFormWrapper
            prePopulateValues={false}
            methods={methods}
            initialData={worker}
            handleSubmit={handleSubmit}
            loading={userDataLoading}>
            <Grid container spacing={3}>
              <Paper className={classes.formPaper} variant="outlined">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <JobsField
                      label="Roles I’m interested in"
                      name="roles"
                      token={access_token}
                      rules={jobRules}
                      multiple={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SpecificSkillsField name="skills" token={helper.access_token} />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="biography"
                      rules={{ maxLength: MAX_BIO_CHARACTERS }}
                      render={({ field }) => (
                        <AppTextField
                          {...field}
                          id="bio"
                          multiline
                          label="Bio"
                          fullWidth
                          style={{ marginBottom: 16 }}
                          inputProps={{ maxlength: MAX_BIO_CHARACTERS }}
                          error={!!errors.biography?.message}
                          helperText={`Characters left: ${
                            MAX_BIO_CHARACTERS - (getValues('biography')?.length || 0)
                          }`}
                        />
                      )}
                    />

                    <Box display="flex" justifyContent="flex-end" marginTop={1}>
                      <Button
                        onClick={goToAIBioGenerator}
                        size="small"
                        startIcon={<FiCpu />}
                        variant="contained"
                        color="primary">
                        Let Vyce AI write my Bio
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>

              {/* // Have to be hidden until Connect module will be deployed to production
                <Grid item xs={12} lg={3}>
                  <Box marginBottom={3}>
                    <Paper className={classes.bioPaper} variant="outlined">
                      <SearchStatusField label="Availability to work" />
                      
                      <CVUploader />
                    </Paper>
                  </Box>
                </Grid> */}
            </Grid>
          </AppFormWrapper>

          <Box
            marginTop={4}
            display="flex"
            className={classes.titleContainer}
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems="center"
            justifyContent="space-between">
            <Box display="flex" alignItems="center" gridGap={8}>
              <Typography className={classes.tabTitle}>Total Work Experience:</Typography>

              <Typography color="secondary" className={classes.tabTitle}>
                {getWorkerExperience(positions)}
              </Typography>
            </Box>

            <Button
              fullWidth={isMobile}
              onClick={handleOpen}
              startIcon={<AiOutlinePlus />}
              color="primary"
              variant="contained">
              Add experience
            </Button>
          </Box>

          <Box marginTop={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.mobileContainer}>
                {positions?.map((position, index: number) => (
                  <WorkExperienceCard
                    editable={true}
                    key={index}
                    updatePosition={updatePosition}
                    deletePosition={deletePosition}
                    position={position}
                    positionIndex={index}
                    helper={helper}
                  />
                ))}
              </Grid>
            </Grid>
          </Box>

          <PositionDialog
            handleClose={handleClose}
            open={open}
            addPosition={addPosition}
            updatePosition={updatePosition}
            helper={helper}
          />
        </>
      )}
    </Box>
  );
};
