import { Box } from '@material-ui/core';

import { PieChart } from '@vyce/core/src/modules/widgetsModule/components';
import { PageLoading } from '@vyce/core/src/components';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { useData } from './hooks';
import { Props } from './types';

export const OnSiteTradesWidget = (props: Props & WidgetWrapperProps) => {
  const { widgetData, loading, handleChartClick } = useData(props);

  return (
    <WidgetWrapper {...props}>
      <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center">
        {loading ? (
          <PageLoading />
        ) : (
          <PieChart data={widgetData} withPercentage={false} handleChartClick={handleChartClick} />
        )}
      </Box>
    </WidgetWrapper>
  );
};
