import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import type { GridColDef } from '@mui/x-data-grid';
import { FiChevronRight } from 'react-icons/fi';
import { RiEyeLine } from 'react-icons/ri';
import dayjs from 'dayjs';
import { AiOutlinePlus } from 'react-icons/ai';

import {
  AppDataGridWithSavedPage,
  AppIconButton,
  ConfirmDialog,
  FilterSystem,
  GridCellWithAvatar,
} from '@vyce/core/src/components';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import {
  approveByWorkerRequest,
  getTimeLogsByWorkerRequest,
  getTimeLogsByShiftRequest,
} from '@vyce/core/src/api/time';
import { TimeLogsByWorkerRequest } from '@vyce/core/src/api/types';
import { getUrlItems } from '@vyce/core/src/utils/url';
import {
  FilterSection,
  TIME_INTERFACE_PERMISSIONS,
  TimeLogByShiftDateItem,
  TimeLogByWorker,
  TimeLogIssue,
  TimeLogStatus,
  TimeTotalInfoProps,
  UserPermission,
} from '@vyce/core/src/types';
import { formatDate, getIsTodayFlag, getUKFormattedDate } from '@vyce/core/src/utils/dates';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { DeviceContext } from '@vyce/core/src/contexts';
import { useBooleanState, useDebounceValue } from '@vyce/core/src/hooks';
import useFilterStyles from '@vyce/core/src/components/FilterSystem/styles';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { isNil } from '@vyce/core/src/utils';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { ExpandTimeLogsInformation } from '@vyce/core/src/components/EditWorkerHoursDialog/ExpandTimeLogsInformation';
import { EditSingleTimeLogWorkerDialog } from '@vyce/core/src/components/EditWorkerHoursDialog/EditSingleTimeLogWorkerDialog';
import { TimeLogWarningCell } from '@vyce/core/src/components/EditWorkerHoursDialog';

import { AddTimeLogsDialog } from '../TimeLogsByShift/components/AddTimeLogsDialog';
import { useStyles } from '../styles';
import { TimeTotalInfo } from '../components';
import { DayTimeColHeader } from './components/DayTimeColHeader';
import { DayTimeValue } from './components/DayTimeValue';
import { DayTimeValuePlug } from './components/DayTimeValuePlug';
import { DailyActivityComponent } from '../ActivityLogs/DailyActivity';

export interface Day {
  basic: number | null;
  overtime: number | null;
}

interface Props {
  companyId?: string;
  userPermissions?: UserPermission[];
}

const clockInFilters = [TimeLogIssue.FACE, TimeLogIssue.LOCATION];
const clockOutFilters = [
  TimeLogIssue.FACE,
  TimeLogIssue.LOCATION,
  TimeLogIssue.MANUAL,
  TimeLogIssue.AUTOMATED,
];

const filtersSections: FilterSection[] = [
  {
    title: 'Time logs issues',
    expanded: true,
    filters: [
      {
        type: 'autocomplete',
        multiple: true,
        label: 'Clock In issues',
        field: 'clockIn',
        values: clockInFilters,
        defaultValue: [],
      },
      {
        type: 'autocomplete',
        multiple: true,
        label: 'Clock Out issues',
        field: 'clockOut',
        values: clockOutFilters,
        defaultValue: [],
      },
    ],
  },
];

export const allLocationsId = 'all';

export const TimeLogsByWorker: React.FC<Props> = ({ companyId, userPermissions }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { siteId, period } = useParams<{ siteId: string; period: string }>();
  const [logs, setLogs] = useState<TimeLogByWorker[]>([]);
  const [logByShift, setLogByShift] = useState<TimeLogByShiftDateItem | null>(null);
  const [locationName, setLocationName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [totals, setTotals] = useState<TimeTotalInfoProps>();
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [locationId, setLocationId] = useState<string>('');
  const [selectedTimeLogData, setSelectedTimeLogData] = useState<{
    userId: string;
    date: string;
    firstName: string;
    lastName: string;
    avatar: string;
    gender: string;
  } | null>(null);
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);
  const [isCreateTimeLogDialogOpen, openCreateTimeLogDialog, closeCreateTimeLogDialog] =
    useBooleanState(false);
  const [isNewLineDialogOpen, openNewLineDialog, closeNewLineDialog] = useBooleanState(false);
  const [
    isExpandTimeLogsInformationDialog,
    openExpandTimeLogsInformationDialog,
    closeExpandTimeLogsInformationDialog,
  ] = useBooleanState(false);
  const [isEditSingleTimeLogDialogOpen, openEditSingleTimeLogDialog, closeEditSingleTimeLogDialog] =
    useBooleanState(false);

  const { offset, substring, setOffset, handlePageChange, handleSearchChange } = useTable({});

  const { isMobile } = useContext(DeviceContext);
  const { handleServerError } = useContext(NotificationContext);
  const filterStyles = useFilterStyles();
  const history = useHistory();

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const periods = period?.split(' - ');
  const periodStart = getUKFormattedDate(periods[0]);
  const periodEnd = getUKFormattedDate(periods[1]);

  const cnaApproveTimelogs = useMemo(
    // probably can be replaced with another permission when backend will update the manager permission list
    () =>
      userPermissions?.length
        ? userPermissions.includes(TIME_INTERFACE_PERMISSIONS.LOCATIONS_AND_SHIFTS)
        : true,
    [userPermissions]
  );

  const getLogs = useCallback(
    async (payload?: TimeLogsByWorkerRequest) => {
      if (!companyId || !locationId || isNil(dOffset)) {
        return;
      }
      try {
        setLoading(true);
        const res = await getTimeLogsByWorkerRequest(companyId, {
          ...(payload ?? {}),
          site_id: locationId !== allLocationsId ? locationId : undefined,
          limit: GRID_PAGE_SIZE,
          start_date: periods[0],
          end_date: periods[1],
          offset: dOffset as number,
          substring,
        });
        setLogs(res.data.items);
        setCount(res.data.count);
        setTotals(res.data.totals);
        generateColumns(res.data.items[0]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
    [companyId, locationId, dOffset, periodStart, periodEnd, substring]
  );

  const getTimeLogsByShift = useCallback(
    async ({ userId, date }: { userId: string; date: string }) => {
      if (!companyId) {
        return;
      }
      try {
        setLoading(true);
        const { data } = await getTimeLogsByShiftRequest(companyId, userId, {
          start_date: date,
          end_date: date,
          site_id: locationId !== 'all' ? locationId : undefined,
        });
        const timeLogsIsEmpty = data.date_items.every(item => item.time_logs.length === 0);
        if (timeLogsIsEmpty) {
          openCreateTimeLogDialog();
        } else if (data.date_items.length && data.date_items[0].time_logs.length > 1) {
          openExpandTimeLogsInformationDialog();
        } else {
          openEditSingleTimeLogDialog();
        }
        setLogByShift(data.date_items[0]);

        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
    [companyId, locationId]
  );

  const approve = async () => {
    if (!companyId || !locationId || !selectedUserId) {
      return;
    }
    try {
      setApproveLoading(true);
      await approveByWorkerRequest(companyId, locationId, selectedUserId, {
        start: periods[0],
        end: periods[1],
      });
      getLogs();
      setApproveLoading(false);
      closeConfirmDialog();
    } catch (e) {
      setApproveLoading(false);
      handleServerError(e);
    }
  };

  const handleNewLineDialogClose = () => {
    closeNewLineDialog();
  };

  const getDayObj = (log: TimeLogByWorker, index: number): Day => ({
    basic: log.basic[index].decimals,
    overtime: log.overtime[index].decimals,
  });

  const handleApproveClick = (userId: string) => {
    setSelectedUserId(userId);
    openConfirmDialog();
  };

  const goToEditView = (name: string, userId: string) => {
    history.push(`${history.location.pathname}/${name}_${userId}`);
  };

  const handleCellClick = ({
    userId,
    date,
    checkInNow,
    ...rest
  }: {
    userId: string;
    date: string;
    checkInNow: boolean;
    firstName: string;
    lastName: string;
    avatar: string;
    gender: string;
  }) => {
    const isToday = getIsTodayFlag(date);
    if ((isToday && checkInNow) || dayjs().isBefore(dayjs(date))) {
      return;
    }
    getTimeLogsByShift({ userId, date });
    setSelectedTimeLogData({ userId, date, ...rest });
  };

  const handleCloseExpandTimeLogsInformationDialog = () => {
    closeExpandTimeLogsInformationDialog();
    setLogByShift(null);
    setSelectedTimeLogData(null);
  };

  const handleCloseSingleEditTimeLogsDialog = () => {
    closeEditSingleTimeLogDialog();
    setLogByShift(null);
    setSelectedTimeLogData(null);
  };

  const actionsAfterDeletingTimeLog = () => {
    handleCloseExpandTimeLogsInformationDialog();
    getLogs();
  };

  const actionsAfterCreatingTimeLog = () => {
    closeCreateTimeLogDialog();
    setLogByShift(null);
  };

  const switchFromEditToExpand = () => {
    openExpandTimeLogsInformationDialog();
    closeEditSingleTimeLogDialog();
  };

  const generateColumns = (log: TimeLogByWorker) => {
    const dayColumns: GridColDef[] = log.basic.map((item, index) => {
      const title = dayjs(item.date).format('ddd DD');
      const isToday = getIsTodayFlag(item.date);

      return {
        field: title,
        minWidth: 105,
        flex: 0.1,
        disableColumnMenu: true,
        renderHeader: () => <DayTimeColHeader title={title} />,
        renderCell: params => (
          <TimeLogWarningCell
            auto_clock_out={params.row?.auto_clock_out_agg?.[index]}
            manual_clock_out={params.row?.manual_clock_out_agg?.[index]}
            check_in_ok={params.row?.check_in_ok_agg?.[index]}
            check_out_ok={params.row?.check_out_ok_agg?.[index]}
            face_in_ok={params.row?.face_in_ok_agg?.[index]}
            face_out_ok={params.row?.face_out_ok_agg?.[index]}
            clock_in_now={isToday && params.row?.checked_in_since}>
            <Box
              onClick={() =>
                handleCellClick({
                  userId: params.row?.user_id,
                  date: item.date,
                  checkInNow: params.row?.checked_in_since,
                  firstName: params.row?.first_name,
                  lastName: params.row?.last_name,
                  avatar: params.row?.avatar,
                  gender: params.row?.gender,
                })
              }
              style={{ cursor: 'pointer' }}>
              {isToday && params.row?.checked_in_since ? (
                <DayTimeValuePlug />
              ) : (
                <DayTimeValue day={getDayObj(params.row as TimeLogByWorker, index)} />
              )}
            </Box>
          </TimeLogWarningCell>
        ),
      } as GridColDef;
    });

    setColumns([
      {
        field: 'first_name',
        headerName: 'Name',
        minWidth: 200,
        disableColumnMenu: true,
        renderCell: params => (
          <GridCellWithAvatar
            link={`${history.location.pathname}/${params.row.first_name}_${params.row.user_id}`}
            avatarUrl={params.row.avatar}
            avatarPlaceholder={getAvatar(params.row.gender)}
            name={`${params.row.first_name} ${params.row.last_name}`}
            primaryBorder={params.row.checked_in_since}
          />
        ),
      },
      {
        field: 'ni_number',
        headerName: 'Ref #',
        flex: 0.1,
        width: 95,
        disableColumnMenu: true,
      },
      ...dayColumns,
      {
        field: 'total_clocked_in_hours_decimals',
        headerName: 'Total Clocked Hours',
        minWidth: 105,
        flex: 0.1,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Box width={60} display="flex" justifyContent="center">
            {row.total_clocked_in_hours_decimals}
          </Box>
        ),
      },
      {
        field: 'total_hours_decimals',
        headerName: 'Total Paid Hours',
        minWidth: 145,
        flex: 0.1,
        disableColumnMenu: true,
      },
      {
        field: '',
        headerName: '',
        width: 180,
        hideSortIcons: true,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Box display="flex" gridGap={16}>
            <AppIconButton onClick={() => goToEditView(row.first_name, row.user_id)}>
              <RiEyeLine />
            </AppIconButton>

            {cnaApproveTimelogs && locationId !== 'all' && (
              <Button
                size="small"
                fullWidth
                onClick={() => handleApproveClick(row.user_id)}
                disabled={row.status === TimeLogStatus.APPROVED}
                variant={row.status === TimeLogStatus.APPROVED ? 'outlined' : 'contained'}
                color={row.status === TimeLogStatus.APPROVED ? undefined : 'primary'}>
                {row.status === TimeLogStatus.APPROVED ? 'Approved' : 'Approve'}
              </Button>
            )}
          </Box>
        ),
      },
    ]);
  };

  const handleFilterChange = (filters: any) => {
    const payload: TimeLogsByWorkerRequest = {};
    if (filters.clockIn.includes(TimeLogIssue.FACE)) {
      payload.face_in_ok = false;
    }
    if (filters.clockOut.includes(TimeLogIssue.FACE)) {
      payload.face_out_ok = false;
    }
    if (filters.clockIn.includes(TimeLogIssue.LOCATION)) {
      payload.check_in_ok = false;
    }
    if (filters.clockOut.includes(TimeLogIssue.LOCATION)) {
      payload.check_out_ok = false;
    }
    if (filters.clockOut.includes(TimeLogIssue.AUTOMATED)) {
      payload.auto_clock_out = true;
    }
    if (filters.clockOut.includes(TimeLogIssue.MANUAL)) {
      payload.manual_clock_out = true;
    }
    getLogs(payload);
  };

  useEffect(() => {
    if (!siteId) return;

    if (siteId === allLocationsId) {
      setLocationId(allLocationsId);
      setLocationName('All locations');
      return;
    }

    const urlItems = getUrlItems(siteId);
    setLocationId(urlItems.id);
    setLocationName(urlItems.name);
  }, [siteId]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  return (
    <>
      <Box>
        {totals && (
          <TimeTotalInfo
            basic={totals.basic_hours_decimals}
            overtime={totals.overtime_hours_decimals}
            totalPaid={totals.total_hours_decimals}
            totalClockedHours={totals.total_clocked_in_hours_decimals}
            totalAdjustments={totals.total_amendments_decimals}
            workers={totals.total_workers}
            period={`${periodStart} - ${periodEnd}`}
          />
        )}
      </Box>

      <Box display="flex" alignItems="center" mt={2} mb={2} justifyContent="space-between">
        <Box display="flex" alignItems="center" gridGap={8}>
          <Typography className={classes.bold} variant="caption">
            {locationName}
          </Typography>

          <FiChevronRight size="20px" color={theme.palette.primary.main} />

          <Typography className={classes.bold} color="primary" variant="caption">
            {periodStart} - {periodEnd}
          </Typography>

          <Box marginLeft={1}>
            <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />
          </Box>
        </Box>

        <Box display="flex" gridGap={16}>
          <Button
            className={filterStyles.filterButton}
            style={{ width: 138 }}
            startIcon={<AiOutlinePlus />}
            size="small"
            onClick={openNewLineDialog}>
            Add worker
          </Button>

          <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />
        </Box>
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={logs}
        loading={loading}
        height="calc(100vh - 372px)"
        getRowId={row => row.user_id}
        columns={columns}
        rowCount={count}
        paginationMode="server"
        sortingMode="server"
        onPageChange={handlePageChange}
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        setOffset={setOffset}
        disableSelectionOnClick
      />

      {logByShift && selectedTimeLogData && isEditSingleTimeLogDialogOpen && (
        <EditSingleTimeLogWorkerDialog
          onSuccess={getLogs}
          userId={selectedTimeLogData.userId}
          log={logByShift.time_logs[0]}
          open={isEditSingleTimeLogDialogOpen}
          handleClose={handleCloseSingleEditTimeLogsDialog}
          date={selectedTimeLogData.date}
          renderAdditionalButton={() => (
            <Button size="large" onClick={switchFromEditToExpand} variant="outlined">
              View this Time Log
            </Button>
          )}
          renderAvatar={() => (
            <GridCellWithAvatar
              avatarUrl={selectedTimeLogData?.avatar}
              avatarPlaceholder={getAvatar(selectedTimeLogData?.gender)}
              name={`${selectedTimeLogData?.firstName} ${selectedTimeLogData?.lastName}`}
            />
          )}
        />
      )}

      {logByShift && selectedTimeLogData && companyId && (
        <AddTimeLogsDialog
          getLogs={getLogs}
          title={`Add a Time Log - ${formatDate(selectedTimeLogData.date, 'dddd DD MMMM')}`}
          companyId={companyId}
          locationId={locationId !== 'all' ? locationId : undefined}
          open={isCreateTimeLogDialogOpen}
          handleClose={actionsAfterCreatingTimeLog}
          dayLog={logByShift}
          userId={selectedTimeLogData.userId}
        />
      )}

      {logByShift && selectedTimeLogData && isExpandTimeLogsInformationDialog && (
        <ExpandTimeLogsInformation
          timeLogByShift={logByShift}
          isOpen={isExpandTimeLogsInformationDialog}
          userId={selectedTimeLogData.userId}
          onClose={handleCloseExpandTimeLogsInformationDialog}
          onSuccess={actionsAfterDeletingTimeLog}
          firstName={selectedTimeLogData.firstName}
          lastName={selectedTimeLogData.lastName}
          renderAvatar={() => (
            <GridCellWithAvatar
              avatarUrl={selectedTimeLogData?.avatar}
              avatarPlaceholder={getAvatar(selectedTimeLogData?.gender)}
              name={`${selectedTimeLogData?.firstName} ${selectedTimeLogData?.lastName}`}
            />
          )}>
          <DailyActivityComponent
            companyId={companyId}
            canViewLocation
            shiftId={logByShift?.time_logs[0]?.shift_id}
            siteName={logByShift?.time_logs[0]?.site_name}
            userId={selectedTimeLogData.userId}
            date={selectedTimeLogData.date}
            withControls={false}
          />
        </ExpandTimeLogsInformation>
      )}
      {companyId && !loading && isNewLineDialogOpen && (
        <AddTimeLogsDialog
          getLogs={getLogs}
          title={`Add a worker to ${periodStart} - ${periodEnd} period`}
          subtitle="Select a team member and create their Time Log for one day here. You will be able to add Logs for other days in detailed view."
          companyId={companyId}
          locationId={locationId !== 'all' ? locationId : undefined}
          open={isNewLineDialogOpen}
          handleClose={handleNewLineDialogClose}
          periods={periods}
        />
      )}

      <ConfirmDialog
        handleClose={closeConfirmDialog}
        open={isConfirmDialogOpen}
        confirmText="Approve"
        title="Do you want to approve this Time log?"
        loading={approveLoading}
        handleConfirm={approve}
      />
    </>
  );
};
