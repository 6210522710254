import React, { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';

import {
  getCisReportsRequest,
  sendCis300ToHMRCRequest,
  downloadCISReportRequest,
  refreshCISReportRequest,
} from '@vyce/core/src/api/legend/pay';
import { Reports } from '@vyce/core/src/views/payroll/Reports';
import { PayModuleContext } from '@vyce/core/src/contexts';

import { useTypedSelector } from '../../hooks';

export const LegendReports: React.FC = () => {
  const { access_token } = useTypedSelector(state => state.helper);
  const { selectedCompanyId } = useContext(PayModuleContext);

  if (!selectedCompanyId) {
    return (
      <Box flex={1} display="flex" height={200} alignItems="center" justifyContent="center">
        <Typography variant="body1">Select a company</Typography>
      </Box>
    );
  }

  return (
    <Reports
      isLegend={true}
      refreshCISReportRequest={refreshCISReportRequest}
      downloadCISReportRequest={downloadCISReportRequest}
      token={access_token}
      companyId={selectedCompanyId}
      getCisReportsRequest={getCisReportsRequest}
      sendCis300ToHMRCRequest={sendCis300ToHMRCRequest}
    />
  );
};
