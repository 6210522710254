import { createContext } from 'react';

import { Dispatch, Action } from 'redux';

import { UserDashboards, WidgetItem, UserPermission } from '@vyce/core/src/types';
import { WidgetWrapperProps } from '../components/Dashboard/WidgetWrapper';

interface Context {
  dashboards?: UserDashboards;
  name: string;
  selectedCompanyId: string;
  permissions: UserPermission[];
  widgetComponent: (props: { widget: WidgetItem; redirectUrl?: string } & WidgetWrapperProps) => JSX.Element;
  inviteButtonComponent?: () => JSX.Element;
  updateUserDashboards: (dashboards: UserDashboards) => (dispatch: Dispatch<Action>) => Promise<void>;
  onOpenUniversalInviteForm?: () => void;
}

export const TimeDashboardModuleContext = createContext({} as Context);

export const TimeDashboardModuleProvider = TimeDashboardModuleContext.Provider;
