import React, { useContext, useEffect, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { Grid, Theme, Typography, useTheme } from '@material-ui/core';
import { FiChevronDown, FiMapPin } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';

import { FieldProps } from '../../types';
import config from '../../api/config';
import { AppTextField } from '../inputs';
import { appAutocompleteUseStyles } from './ControlledAutocomplete';
import { DeviceContext } from '../../contexts';

const loadScript = (src: string, position: HTMLElement | null, id: string) => {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

let autocomplete: any = null;

interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

interface Props extends FieldProps {
  allCountries?: boolean;
}

export const LocationField: React.FC<Props> = ({
  margin = undefined,
  name = 'postcode',
  disabled,
  rules = { required: 'This field is required' },
  allCountries,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme<Theme>();
  const classes = appAutocompleteUseStyles({});
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef(false);
  const { isMobile } = useContext(DeviceContext);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#geocode-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.googleSearchApiKey}&libraries=places&language=en`,
        document.querySelector('head'),
        'geocode-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback: (results?: PlaceType[]) => void) => {
        (autocomplete as any).getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocomplete && (window as any).google) {
      autocomplete = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocomplete) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: !allCountries && { country: ['UK'] } },
      (results?: PlaceType[]) => {
        if (active) {
          let newOptions: PlaceType[] = [];

          if (value) {
            newOptions = [value];
          }
          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={props => (
        <Autocomplete
          value={props.field.value || ''}
          onChange={(e, newValue) => {
            props.field.onChange(newValue?.description || '');
            setValue(newValue);
          }}
          id="postcode-autocomplete"
          getOptionLabel={option => (typeof option === 'string' ? option : option.description || '')}
          filterOptions={x => x}
          options={uniqBy(options, 'description')}
          disablePortal={isMobile}
          fullWidth
          disabled={disabled}
          popupIcon={<FiChevronDown size="25px" color="#A0A3BD" />}
          closeIcon={<IoClose size="25px" color="#A0A3BD" />}
          classes={classes}
          autoComplete={false}
          includeInputInList
          filterSelectedOptions
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={params => (
            <AppTextField
              {...params}
              label="Post Code, Zip Code or Address"
              margin={margin}
              autoComplete="off"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
              error={errors && !!errors[name]?.message}
              helperText={((errors && errors[name]?.message) as string) || ''}
              fullWidth
            />
          )}
          renderOption={option => {
            const matches = option?.structured_formatting?.main_text_matched_substrings || [];
            const parts = parse(
              option?.structured_formatting?.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length])
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <FiMapPin color={theme.palette.text.secondary} style={{ marginRight: theme.spacing(2) }} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 600 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                  <div>
                    <Typography variant="caption" color="textSecondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            );
          }}
        />
      )}
    />
  );
};
