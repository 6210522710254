import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  selector: {
    borderRadius: 6,
    '& .MuiInputBase-input': {
      paddingTop: 10,
    },
  },
  count: {
    fontWeight: 600,
    textWrap: 'nowrap',
  },

  buttonWrapper: {
    width: '40px',
    height: '40px',
    padding: 12,
    minWidth: 'max-content !important',

    '& .MuiButton-startIcon': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));
