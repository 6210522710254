import { axios } from '@vyce/core/src/api';

import config from '../config';
import {
  CreateSite,
  DailySchedule,
  EmployeeActivityLog,
  LocationUserDTO,
  OnSiteTradesData,
  Site,
  SiteShift,
  TimeLogByPeriod,
  TimeLogByShift,
  TimeLogBySite,
  TimeLogByWorker,
  TimePeriodSettings,
  TimeTotalInfoProps,
  TopPerformingTeamMembersLatWeekDTO,
  WorkersWorkedTimeOverview,
} from '../../types';
import {
  ActiveCheckInDTO,
  AddSiteWorkerRequest,
  AddTimePeriodPayload,
  CheckInWorkerDTO,
  CheckInWorkersRequestProps,
  CheckOutWorkerDTO,
  CheckOutWorkersRequestProps,
  ClockedOutRolesDataRequest,
  CreateShiftRequest,
  CustomLocationField,
  CustomLocationFieldsDetailsDTO,
  CustomLocationFieldsDTO,
  EditWorkerHoursRequestProps,
  ExportTimeLogsRequestData,
  GetEventsRequest,
  GetEventsRequestPayload,
  GetSitesPayload,
  GetSiteWorkersRequest,
  GetTimeLogsPayload,
  GetTimePeriodsRequest,
  LocationFieldDetailsDTO,
  ManualClockInOut,
  ReportSettingsDTO,
  TimeLogDTO,
  TimeLogsByWorkerRequest,
  TimeLogsRequestProps,
  TimeLogTotal,
  UpdatedReportSettingsData,
  UpdateShiftRequest,
  UpdateSiteRequest,
  WorkerLookupDTO,
} from '../types';
import dayjs from 'dayjs';

const URL = `${config.timeApiUrl}`;

export const createSiteRequest = (companyId: string, site: CreateSite) => {
  return axios.post(`${URL}/companies/${companyId}/sites`, site);
};

export const getSitesRequest = (companyId: string, data: GetSitesPayload) => {
  return axios.post(`${URL}/companies/${companyId}/sites/search`, data);
};

export const getSiteRequest = (companyId: string, siteId: string) => {
  return axios.get(`${URL}/companies/${companyId}/sites/${siteId}`);
};

export const deleteSiteRequest = (companyId: string, siteId: string) => {
  return axios.delete(`${URL}/companies/${companyId}/sites/${siteId}/remove`);
};

export const updateSiteRequest = ({ companyId, siteId, site }: UpdateSiteRequest) => {
  return axios.patch(`${URL}/companies/${companyId}/sites/${siteId}`, site);
};

export const createShiftRequest = ({ companyId, shift, siteId }: CreateShiftRequest) => {
  return axios.post(`${URL}/companies/${companyId}/sites/${siteId}/shifts`, shift);
};

export const updateShiftRequest = ({ companyId, siteId, shiftId, shift }: UpdateShiftRequest) => {
  return axios.patch(`${URL}/companies/${companyId}/sites/${siteId}/shifts/${shiftId}`, shift);
};

export const deleteShiftRequest = (companyId: string, siteId: string, shiftId: string) => {
  return axios.delete(`${URL}/companies/${companyId}/sites/${siteId}/shifts/${shiftId}`);
};

export const addSiteWorkerRequest = ({ companyId, shiftId, siteId, worker }: AddSiteWorkerRequest) => {
  return axios.put(`${URL}/companies/${companyId}/sites/${siteId}/shifts/${shiftId}/workers`, worker);
};

export const updateSiteWorkerRequest = ({ companyId, shiftId, siteId, worker }: AddSiteWorkerRequest) => {
  return axios.patch(
    `${URL}/companies/${companyId}/sites/${siteId}/shifts/${shiftId}/workers/${worker.user_id}`,
    worker
  );
};

export const getSiteWorkersRequest = ({ companyId, payload }: GetSiteWorkersRequest) => {
  return axios.post(`${URL}/companies/${companyId}/sites/workers/search`, payload);
};

export const getWorkerSitesRequest = ({
  companyId,
  userId,
  payload,
}: GetSiteWorkersRequest & { userId: string }) => {
  return axios.post<{ items: Site[]; count: number }>(
    `${URL}/companies/${companyId}/sites/users/${userId}/search`,
    payload
  );
};

export const deleteShiftWorkerRequest = (
  companyId: string,
  siteId: string,
  shiftId: string,
  workerId: string
) => {
  return axios.delete(`${URL}/companies/${companyId}/sites/${siteId}/shifts/${shiftId}/workers/${workerId}`);
};

export const deleteAllShiftsWorkerRequest = (companyId: string, workerId: string) => {
  return axios.delete(`${URL}/companies/${companyId}/sites/workers/${workerId}`);
};

export const getActivityLogEventsRequest = ({ companyId, payload }: GetEventsRequest) => {
  return axios.post(`${URL}/monitoring/companies/${companyId}/activity_log`, payload);
};

export const getTimePeriodsRequest = ({ companyId, payload }: GetTimePeriodsRequest) => {
  return axios.post(`${URL}/time_periods/companies/${companyId}/time_periods/search`, payload);
};

export const getEventsRequest = ({ companyId, payload }: GetEventsRequest) => {
  return axios.post(`${URL}/companies/${companyId}/events/search`, payload);
};

export const getMapViewEventsRequest = (companyId: string, siteId?: string | null) => {
  const now = dayjs().utc(false);
  const gte = now.startOf('day');
  const lte = now.endOf('day');
  return axios.post(`${URL}/monitoring/companies/${companyId}/map_view`, {
    limit: 5000,
    site_id: siteId,
    time_period: {
      lte: lte.format(),
      gte: gte.format(),
    },
  });
};

export const manualClockInOutRequest = ({
  companyId,
  userId,
  payload,
}: {
  companyId: string;
  userId: string;
  payload: ManualClockInOut;
}) => {
  return axios.post(`${URL}/companies/${companyId}/users/${userId}/checks`, payload);
};

export const checkUserActiveCheckInRequest = ({
  companyId,
  userId,
}: {
  companyId: string;
  userId: string;
}) => {
  return axios.post<ActiveCheckInDTO>(
    `${URL}/monitoring/companies/${companyId}/users/${userId}/active_check_in`
  );
};

export const getLookupSiteWorkersRequest = ({ companyId, payload }: GetSiteWorkersRequest) => {
  return axios.post<{ count: number; items: WorkerLookupDTO[] }>(
    `${URL}/companies/${companyId}/sites/workers/lookup`,
    payload
  );
};

export const getCheckInWorkersRequest = ({
  companyId,
  payload,
}: {
  companyId: string;
  payload: CheckInWorkersRequestProps;
}) => {
  return axios.post<{ count: number; items: CheckInWorkerDTO[] }>(
    `${URL}/monitoring/companies/${companyId}/workers/checked_in`,
    payload
  );
};

export const getCheckInWorkersRolesRequest = ({
  companyId,
  payload,
}: {
  companyId: string;
  payload: {
    limit: number;
    offset: number;
  };
}) => {
  return axios.post<{ items: string[] }>(
    `${URL}/monitoring/companies/${companyId}/workers/checked_in/roles`,
    payload
  );
};

export const getCheckOutWorkersRolesRequest = ({
  companyId,
  payload,
}: {
  companyId: string;
  payload: {
    limit: number;
    offset: number;
  };
}) => {
  return axios.post<{ items: string[] }>(
    `${URL}/monitoring/companies/${companyId}/workers/checked_out/roles`,
    payload
  );
};

export const getCheckOutWorkersRequest = ({
  companyId,
  payload,
}: {
  companyId: string;
  payload: CheckOutWorkersRequestProps;
}) => {
  return axios.post<{ count: number; items: CheckOutWorkerDTO[] }>(
    `${URL}/monitoring/companies/${companyId}/workers/checked_out`,
    payload
  );
};

export const getTimeLogsByPeriodRequest = (companyId: string) => {
  return axios.post<{ count: number; items: TimeLogByPeriod[] }>(
    `${URL}/time_logs/companies/${companyId}/time_logs_by_period`
  );
};

export const getTimeLogsByLocationRequest = (companyId: string, payload: TimeLogsRequestProps) => {
  return axios.post<{ count: number; items: TimeLogBySite[]; totals: TimeTotalInfoProps }>(
    `${URL}/time_logs/companies/${companyId}/time_logs_by_site`,
    payload
  );
};

export const getTimeLogsByWorkerRequest = (companyId: string, payload: TimeLogsByWorkerRequest) => {
  return axios.post<{ count: number; items: TimeLogByWorker[]; totals: TimeTotalInfoProps }>(
    `${URL}/time_logs/companies/${companyId}/time_logs_by_worker`,
    payload
  );
};

export const getTimeLogsByShiftRequest = (
  companyId: string,
  userId: string,
  period: { site_id?: string; start_date: string; end_date: string }
) => {
  return axios.post<TimeLogByShift>(
    `${URL}/time_logs/companies/${companyId}/time_logs_by_worker_shift?user_id=${userId}`,
    period
  );
};

export const approveByPeriodRequest = (companyId: string, payload: DailySchedule) => {
  return axios.post(`${URL}/time_logs/companies/${companyId}/periods/approval`, payload);
};

export const approveBySiteRequest = (companyId: string, siteId: string, payload: DailySchedule) => {
  return axios.post(`${URL}/time_logs/companies/${companyId}/periods/sites/${siteId}/approval`, payload);
};

export const approveByWorkerRequest = (
  companyId: string,
  siteId: string,
  userId: string,
  payload: DailySchedule
) => {
  return axios.post(
    `${URL}/time_logs/companies/${companyId}/periods/sites/${siteId}/users/${userId}/approval`,
    payload
  );
};

export const getTimeWorkerRequest = (userId: string, companyId: string) => {
  return axios.get(`${URL}/users/${userId}?company_id=${companyId}`);
};

export const editWorkerHoursRequest = ({
  companyId,
  day,
  amendments,
  userId,
  siteId,
  shiftId,
}: EditWorkerHoursRequestProps) => {
  return axios.post(
    `${URL}/time_logs/companies/${companyId}/periods/${day}/sites/${siteId}/shifts/${shiftId}/users/${userId}`,
    amendments
  );
};

export const postExportTimeLogsRequest = ({ companyId, data }: ExportTimeLogsRequestData) => {
  return axios.post(`${URL}/reports/companies/${companyId}/export_time_logs_to_xlsx`, data, {
    headers: {
      accept: 'application/json',
    },
    responseType: 'blob',
  });
};

export const setPeriodSettingsRequest = (companyId: string, payload: TimePeriodSettings) => {
  return axios.put(`${URL}/companies/${companyId}/period_settings`, payload);
};

export const getPeriodSettingsRequest = (companyId: string) => {
  return axios.get(`${URL}/companies/${companyId}/period_settings`);
};

export const postWorkersWorkedTimeOverviewRequest = ({
  companyId,
  payload,
}: {
  companyId: string;
  payload: { approved: boolean };
}) => {
  return axios.post<{ count: number; items: WorkersWorkedTimeOverview[] }>(
    `${URL}/time_logs/companies/${companyId}/widgets/workers_worked_time_overview`,
    payload
  );
};

export const getTopPerformingTeamMembersLatWeekRequest = ({ companyId }: { companyId: string }) => {
  return axios.get<TopPerformingTeamMembersLatWeekDTO>(
    `${URL}/time_logs/companies/${companyId}/widgets/top_performing_workers_last_week`
  );
};

export const getTimeLogsRequest = (userId: string, payload: GetTimeLogsPayload) => {
  return axios.post<{ count: number; items: TimeLogDTO[]; totals: TimeLogTotal }>(
    `${URL}/users/${userId}/time_logs/search`,
    payload
  );
};

export const getUserActivityLogEventsRequest = ({
  userId,
  payload,
}: {
  userId: string;
  payload: GetEventsRequestPayload;
}) => {
  return axios.post<{ items: EmployeeActivityLog[] }>(`${URL}/users/${userId}/activity_log`, payload);
};

export const addTimePeriodRequest = (companyId: string, payload: AddTimePeriodPayload) => {
  return axios.post<{ count: number; items: TimeLogDTO[]; totals: TimeLogTotal }>(
    `${URL}/companies/${companyId}/time_periods`,
    payload
  );
};

export const getTimeModulePreferencesRequest = (companyId: string) => {
  return axios.get(`${URL}/companies/${companyId}/preferences`);
};

export const updateTimeModulePreferencesRequest = (companyId: string, countAutoClockOut: boolean) => {
  return axios.put(`${URL}/companies/${companyId}/preferences`, { count_auto_check_out: countAutoClockOut });
};

export const getWorkerTimeLogsByShiftRequest = (
  userId: string,
  period: { site_id: string; start_date: string; end_date: string }
) => {
  return axios.post<TimeLogByShift>(`${URL}/users/${userId}/time_logs/time_logs_by_worker_shift`, period);
};

export const getCustomLocationDetailsFields = ({ companyId }: { companyId: string }) => {
  return axios.get<CustomLocationFieldsDetailsDTO>(`${URL}/reports/companies/${companyId}/sites/fields`);
};

export const updateCustomLocationDetailsFields = ({
  companyId,
  data,
}: {
  companyId: string;
  data: { fields: LocationFieldDetailsDTO[] };
}) => {
  return axios.put<CustomLocationFieldsDetailsDTO>(
    `${URL}/reports/companies/${companyId}/sites/fields`,
    data
  );
};

export const getExportingTimeLogsSettings = ({ companyId }: { companyId: string }) => {
  return axios.get<ReportSettingsDTO>(`${URL}/reports/companies/${companyId}/report_settings`);
};

export const updateExportingTimeLogsSettings = ({
  companyId,
  data,
}: {
  companyId: string;
  data: UpdatedReportSettingsData;
}) => {
  return axios.put<ReportSettingsDTO>(`${URL}/reports/companies/${companyId}/report_settings`, data);
};

export const restoreExportingTimeLogsSettings = ({ companyId }: { companyId: string }) => {
  return axios.get<ReportSettingsDTO>(`${URL}/reports/companies/${companyId}/report_settings/restore`);
};

export const getCustomLocationFields = ({ companyId, siteId }: { companyId: string; siteId: string }) => {
  return axios.get<CustomLocationFieldsDTO>(
    `${URL}/reports/companies/${companyId}/sites/${siteId}/fields/values`
  );
};

export const updateCustomLocationFields = ({
  companyId,
  siteId,
  data,
}: {
  companyId: string;
  siteId: string;
  data: { values: Omit<CustomLocationField, 'name'>[] };
}) => {
  return axios.put<CustomLocationFieldsDTO>(
    `${URL}/reports/companies/${companyId}/sites/${siteId}/fields/values`,
    data
  );
};

export const getOnSiteTradesDataRequest = (companyId: string) => {
  return axios.post<OnSiteTradesData>(`${URL}/time_logs/companies/${companyId}/widgets/on_site_trades`);
};

export const deleteTimeLogRequest = (companyId: string, workday: string, userId: string) => {
  return axios.delete(`${URL}/companies/${companyId}/workday/${workday}/users/${userId}`);
};

export const getLocationUsersRequest = ({
  companyId,
  data,
}: {
  companyId: string;
  data: {
    offset: number;
    limit: number;
    site_id?: string;
    status?: string;
    substring?: string;
  };
}) => {
  return axios.post<{ count: number; items: LocationUserDTO[] }>(
    `${URL}/monitoring/companies/${companyId}/location/workers`,
    data
  );
};

export const getClockedOutRolesRequest = ({
  companyId,
  data,
}: {
  companyId: string;
  data: ClockedOutRolesDataRequest;
}) => {
  return axios.post<{ items: string[] }>(
    `${URL}/monitoring/companies/${companyId}/workers/checked_out/roles`,
    data
  );
};

export const getDidntClockOutRolesRequest = ({
  companyId,
  payload,
}: {
  companyId: string;
  payload: ClockedOutRolesDataRequest;
}) => {
  return axios.post<{ items: string[] }>(
    `${URL}/monitoring/companies/${companyId}/workers/did_not_clock_out/roles`,
    payload
  );
};

export const getDidntClockedOutWorkersRequest = ({
  companyId,
  payload,
}: {
  companyId: string;
  payload: CheckOutWorkersRequestProps;
}) => {
  return axios.post<{ count: number; items: CheckOutWorkerDTO[] }>(
    `${URL}/monitoring/companies/${companyId}/workers/did_not_clock_out`,
    payload
  );
};

export const getWorkerShiftsRequest = ({
  companyId,
  userId,
  siteId,
}: {
  companyId: string;
  userId: string;
  siteId: string;
}) => {
  return axios.post<{ count: number; items: SiteShift[] }>(
    `${URL}/monitoring/companies/${companyId}/users/${userId}/shifts/search`,
    {
      limit: 1000,
      offset: 0,
      order_by: [{ field: 'name', desc: true }],
      site_id: siteId,
    }
  );
};
