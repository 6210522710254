import { useContext, useEffect, useMemo, useState } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';

import { useBooleanState } from '@vyce/core/src/hooks';
import {
  getCheckInWorkersRequest,
  getCheckOutWorkersRequest,
  getCheckOutWorkersRolesRequest,
  getCheckInWorkersRolesRequest,
} from '@vyce/core/src/api/time';
import { formatTimeSortModel } from '@vyce/core/src/utils/sorting';
import { CheckInWorkerDTO, CheckOutWorkerDTO } from '@vyce/core/src/api/types';
import { useInterval } from '@vyce/core/src/hooks/useInterval';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { TIME_INTERFACE_PERMISSIONS } from '@vyce/core/src/types';
import { useTabFocus } from '@vyce/core/src/hooks/useTabFocus';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';

import { Props } from '../types';

const defaultOption = { value: 'all', name: 'All Locations' };
const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'desc' }];

export const useWidgetData = ({ selectedCompanyId, locations, userPermissions, type }: Props) => {
  const [workers, setWorkers] = useState<CheckInWorkerDTO[] | CheckOutWorkerDTO[]>([]);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [isSmallView, setSmallView] = useState(false);
  const [isShowSearchField, setShowSearchFieldTrue] = useBooleanState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(defaultOption.value);
  const [roleOptions, setRoleOptions] = useState<{ id: string; name: string }[]>([]);
  const [isFilterDialogOpen, openFilterDialog, closeFilterDialog] = useBooleanState(false);
  const [filters, setFilters] = useState<{ roles: string[] } | null>(null);
  const { isTabFocused } = useTabFocus();
  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);

  const {
    sortModel,
    offset,
    total,
    substring,
    setTotal,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({
    defaultSortModel,
  });

  const methods = useForm<{ roles: Record<string, boolean> }>({
    defaultValues: { roles: {} },
  });

  const roles = methods.watch('roles');

  const isFilterWasActivated = Object.entries(roles).some(item => item[1] === false);

  const cnaViewWorker = useMemo(
    () => (userPermissions?.length ? userPermissions.includes(TIME_INTERFACE_PERMISSIONS.USERS) : true),
    [userPermissions]
  );

  const locationOptions = useMemo(() => {
    if (!locations) return [defaultOption];
    const options = locations.map(item => ({ value: item.uuid, name: item.name }));
    return [...options, defaultOption];
  }, [locations]);

  const filterSettings = useMemo(
    () => [
      {
        options: roleOptions,
        title: 'Which roles should be displayed?',
        type: 'roles',
      },
    ],
    [roleOptions]
  );

  const restoreFilters = () => {
    const data = methods.getValues();
    const restoredRoles = Object.keys(data.roles).reduce((sum, curr) => {
      sum[curr] = true;
      return sum;
    }, {} as Record<string, boolean>);

    methods.reset({ roles: restoredRoles });
    handleChangeFilter({ roles: restoredRoles });
  };

  const handleChangeFilter = (data: { roles: Record<string, boolean> }) => {
    const filteredRoles = Object.keys(data.roles).filter(
      item => data.roles[item] || data.roles[item] === undefined
    );
    setFilters({ roles: filteredRoles });
  };

  const getRoles = async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      const request = type === 'clock_in' ? getCheckInWorkersRolesRequest : getCheckOutWorkersRolesRequest;
      const { data } = await request({
        companyId: selectedCompanyId,
        payload: {
          offset: 0,
          limit: 100,
        },
      });
      setRoleOptions(data.items.map(item => ({ id: item, name: item })));
    } catch (e) {
      handleServerError(e);
    }
  };
  const getMembers = async () => {
    if (!selectedCompanyId || !isTabFocused) {
      return;
    }

    setLoadingTrue();
    try {
      const request = type === 'clock_in' ? getCheckInWorkersRequest : getCheckOutWorkersRequest;

      const { data } = await request({
        companyId: selectedCompanyId,
        payload: {
          offset: offset,
          limit: GRID_PAGE_SIZE,
          order_by: formatTimeSortModel<CheckInWorkerDTO>(sortModel),
          site_ids: selectedLocationId !== 'all' ? [selectedLocationId] : undefined,
          worker_substring: substring,
          ...filters,
        },
      });
      setTotal(data.count);
      setWorkers(data.items);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const onSubmit = async () => {
    const data = methods.getValues();
    handleChangeFilter(data);
  };

  const getNewData = () => {
    getMembers();
    getRoles();
  };

  useInterval(getNewData, 10000);

  useEffect(() => {
    getMembers();
  }, [selectedCompanyId, selectedLocationId, offset, sortModel, filters, substring]);

  useEffect(() => {
    getRoles();
  }, [selectedCompanyId]);

  return {
    workers,
    total,
    locationOptions,
    selectedLocationId,
    loading,
    sortModel,
    cnaViewWorker,
    methods,
    filterSettings,
    isFilterDialogOpen,
    isMobile,
    isSmallView,
    isShowSearchField,
    isFilterWasActivated,
    openFilterDialog,
    closeFilterDialog,
    onSubmit,
    restoreFilters,
    handlePageChange,
    setSelectedLocationId,
    handleSortModelChange,
    handleSearchChange,
    setSmallView,
    setShowSearchFieldTrue,
  };
};
