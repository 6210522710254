import React, { useEffect, useState } from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';

import { getDayQuoteRequest } from '@vyce/core/src/api/quotes';
import { monochrome } from '@vyce/core/src/theme/styles';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

export const DayQuoteWidget = (props: WidgetWrapperProps) => {
  const theme = useTheme();
  const [quote, setQuote] = useState<string>('');
  const [author, setAuthor] = useState<string>('');

  const getQuote = async () => {
    try {
      const res = await getDayQuoteRequest();
      // TODO find quote api
      // const quoteObj = res.data[0];
      // setQuote(quoteObj?.quote || '');
      // setAuthor(quoteObj?.author || '');
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getQuote();
  }, []);

  return (
    <WidgetWrapper {...props}>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" marginTop={-1}>
        {quote && author && (
          <>
            <Typography style={{ fontSize: '15px', fontWeight: 500, textAlign: 'center' }} color="primary">
              "{quote}"
            </Typography>
            <Typography
              style={{
                marginTop: '4px',
                fontSize: '13px',
                fontWeight: 500,
                color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
              }}>
              {author}
            </Typography>
          </>
        )}
      </Box>
    </WidgetWrapper>
  );
};
