import React, { useCallback } from 'react';

import { GridColDef } from '@mui/x-data-grid';

import { GridCellWithAvatar } from '@vyce/core/src/components';
import { getTime } from '@vyce/core/src/utils/dates';
import { getAvatar } from '@vyce/core/src/utils';
import { WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { WidgetTemplate } from './WidgetTemplate';
import { Props } from './types';

export const ClockInWidget = (props: Props & WidgetWrapperProps) => {
  const getColumns = useCallback(
    (cnaViewWorker: boolean) =>
      [
        {
          field: 'first_name',
          headerName: 'Name',
          flex: 1,
          disableColumnMenu: true,
          minWidth: 150,
          renderCell: params => (
            <GridCellWithAvatar
              avatarUrl={params.row.photo_url}
              avatarPlaceholder={getAvatar(params.row.gender)}
              link={cnaViewWorker ? `/time/users/${params.row.first_name}_${params.row.user_id}/logs` : ''}
              name={`${params.row.first_name} ${params.row.last_name}`}
            />
          ),
        },
        {
          field: 'job_title',
          headerName: 'Job Title',
          flex: 0.8,
          disableColumnMenu: true,
          minWidth: 100,
        },
        {
          field: 'site_name',
          headerName: 'Location',
          flex: 0.8,
          disableColumnMenu: true,
          minWidth: 100,
        },
        {
          field: 'checked_in',
          headerName: 'Since',
          flex: 0.8,
          disableColumnMenu: true,
          minWidth: 100,
          valueGetter: params => getTime(params.row.checked_in),
        },
      ] as GridColDef[],
    []
  );

  return <WidgetTemplate getColumns={getColumns} {...props} type="clock_in" />;
};
