import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
  Button,
  useTheme,
} from '@material-ui/core';
import noop from 'lodash/noop';

import { DeviceContext } from '../contexts/deviceContext';
import { MdTune } from 'react-icons/md';
import { WidgetItem } from '../types';
import { AppCheckbox } from './inputs';
import { monochrome } from '../theme/styles';
import { useBooleanState } from '../hooks';
import { MAIN_CONTAINER_ID } from '../constants';

interface Props {
  onWidgetStatusChange: Function;
  items: WidgetItem[];
  onUpdateDashboard?: () => void;
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    },
    dialogContent: {
      overflow: 'hidden',
    },
  })
);

const WidgetCheckboxItem = ({
  name,
  is_active,
  system_name,
  onChange,
}: WidgetItem & { onChange: (event: ChangeEvent<HTMLInputElement>) => void }) => {
  const classes = useStyles();
  const inputProps: InputProps = { 'data-system-name': system_name };

  return (
    <Box key={system_name} marginTop="12px">
      <FormControlLabel
        classes={{ label: classes.label }}
        control={
          <AppCheckbox
            variant="rectangle"
            onChange={onChange}
            color="primary"
            checked={is_active}
            inputProps={inputProps}
          />
        }
        label={name}
      />
    </Box>
  );
};

export const CustomizeDashboard: React.FC<Props> = ({
  onWidgetStatusChange,
  items,
  onUpdateDashboard = noop,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpenDialog, setCloseDialog] = useBooleanState(false);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onWidgetStatusChange(event.target.dataset.systemName);
    },
    [onWidgetStatusChange]
  );

  const handleUpdateClick = () => {
    setCloseDialog();
    onUpdateDashboard();
  };

  return (
    <>
      <Button
        onClick={setOpenDialog}
        size="small"
        style={{ backgroundColor: theme.palette.background.paper }}
        startIcon={<MdTune />}
        variant="outlined"
        color="primary">
        Customise your dashboard
      </Button>

      <Dialog
        fullScreen={isMobile}
        maxWidth="md"
        open={open}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        onClose={setCloseDialog}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">Customise your dashboard!</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.label}>Which windows should be displayed?</Typography>

          <Box
            marginTop={2}
            marginLeft="-5px"
            overflow="auto"
            maxHeight="calc(100vh - 315px)"
            height="max-content">
            {items.map(item => (
              <WidgetCheckboxItem key={item.system_name} {...item} onChange={handleChange} />
            ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button size="large" onClick={setCloseDialog} variant="outlined">
            Close
          </Button>
          <Button size="large" color="primary" onClick={handleUpdateClick} variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
