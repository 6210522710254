import React from 'react';

import { Box, Typography, MenuItem } from '@material-ui/core';
import type { GridColDef } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import noop from 'lodash/noop';

import { AppSelect } from '@vyce/core/src/components/inputs';
import { AppDataGrid, GridCellWithAvatar } from '@vyce/core/src/components';
import { ControlledAutocomplete } from '@vyce/core/src/components/controlled-inputs';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';
import { TextStatusComponent } from '@vyce/core/src/components/Statuses/TextStatusComponent';

import { useWidgetData } from './hooks';
import { Props } from './types';
import { useStyles } from './styles';
import { defaultStatusOptions, statuses } from './config';

export const QualificationWidget = (props: Props & WidgetWrapperProps) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    teamQualifications,
    qualificationsSelectOptions,
    teamsSelectOptions,
    selectedTeamId,
    total,
    loading,
    selectedStatusId,
    methods,
    handlePageChange,
    setSelectedTeamId,
    setSelectedStatusId,
  } = useWidgetData(props);

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      sortable: false,
      renderCell: params => (
        <GridCellWithAvatar
          avatarUrl={params.row.photo}
          avatarPlaceholder={getAvatar(params.row.gender)}
          name={`${params.row.first_name} ${params.row.last_name}`}
          link={`${history.location.pathname}/${params.row?.first_name}_${params.row?.user_id}`}
        />
      ),
    },
    {
      field: 'qualification',
      headerName: 'Qualification',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
      sortable: false,
      valueGetter: params => params.row?.title ?? '',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
      sortable: false,
      renderCell: params => (
        <TextStatusComponent
          verified={params.row?.status !== statuses.Indefinite}
          expiry_date={params.row?.expiry_date}
        />
      ),
    },
  ];

  const handleTeamChange = (event: any) => {
    setSelectedTeamId(event.target.value);
  };

  const handleStatusChange = (event: any) => {
    setSelectedStatusId(event.target.value);
  };

  return (
    <WidgetWrapper {...props}>
      <Box width="100%" height="100%" display="flex" flexDirection="column" gridGap={12}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(noop)}>
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
              gridGap={12}>
              <AppSelect
                onChange={handleTeamChange}
                value={selectedTeamId}
                labelId="select-trade"
                fullWidth
                className={classes.selector}>
                {teamsSelectOptions.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </AppSelect>
              <Box display="flex" width="100%" alignItems="center" gridGap={8}>
                <Typography>with</Typography>

                <ControlledAutocomplete
                  name="qualification"
                  margin="none"
                  items={qualificationsSelectOptions}
                  label="Qualification"
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" gridGap={8}>
                <Typography>at</Typography>
                <AppSelect
                  onChange={handleStatusChange}
                  value={selectedStatusId}
                  labelId="select-status"
                  fullWidth
                  className={classes.selector}>
                  {defaultStatusOptions.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </AppSelect>
                <Typography>status</Typography>
              </Box>
            </Box>
          </form>
        </FormProvider>
        <AppDataGrid
          rows={teamQualifications}
          getRowId={row => row.certificate_id}
          columns={columns}
          noPaper
          rowHeight={80}
          height="calc(100% - 50px)"
          rowCount={total}
          pageSize={GRID_PAGE_SIZE}
          paginationMode="server"
          sortingMode="server"
          rowsPerPageOptions={[GRID_PAGE_SIZE]}
          onPageChange={handlePageChange}
          disableSelectionOnClick
          loading={loading}
        />
      </Box>
    </WidgetWrapper>
  );
};
