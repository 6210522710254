import React, { useContext } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { VscVerified } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { HiOutlineArrowRight, HiOutlineLightBulb } from 'react-icons/hi';
import { BiError, BiErrorAlt } from 'react-icons/bi';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { getColor } from '@vyce/core/src/theme/styles';
import { ColorThemeContext } from '@vyce/core/src/contexts/colorThemeContext';
import { ColorOption } from '@vyce/core/src/types';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

interface Props {
  title: string;
  subtitle: string;
  buttonText: string;
  redirectTo: string;
  isActionButton?: boolean;
  action?: Function;
  type?: ColorOption;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      height: '48px',
      minWidth: '48px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontWeight: 600,
    },
    addMoreButton: {
      fontWeight: 500,
      textDecoration: 'none',
      padding: '14px 16px',
      width: '100%',
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.16)' : '#EBECFE',
      color: theme.palette.primary.main,
    },
  })
);

export const CheckerWidget = ({
  title,
  subtitle,
  buttonText,
  redirectTo,
  type = 'success',
  isActionButton,
  action = () => {},
  ...rest
}: Props & WidgetWrapperProps) => {
  const classes = useStyles();
  const { colorTheme } = useContext(ColorThemeContext);
  const colors: any = getColor(colorTheme === 'dark');

  return (
    <WidgetWrapper {...rest}>
      <Box display="flex" height="100%" width="100%">
        <Box width="100%" display="flex" flexDirection="column" gridGap={3}>
          <Box display="flex" flex="1 0 auto">
            <div className={classes.iconContainer} style={{ backgroundColor: colors[`${type}BG`] }}>
              {type === 'success' && <VscVerified size="18px" color={colors[`${type}Dark`]} />}
              {type === 'error' && <BiErrorAlt size="18px" color={colors[`${type}Dark`]} />}
              {type === 'warning' && <BiError size="18px" color={colors[`${type}Dark`]} />}
            </div>

            <Box marginLeft={2} minHeight={70}>
              <Typography variant="subtitle1" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {subtitle}
              </Typography>
            </Box>
          </Box>

          {isActionButton ? (
            <Button onClick={() => action()} variant="outlined" fullWidth color="primary">
              {buttonText}
            </Button>
          ) : (
            <Link className={classes.addMoreButton} to={redirectTo}>
              <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                  <HiOutlineLightBulb size="18px" />
                  <Box marginLeft={1}>{buttonText}</Box>
                </Box>

                <HiOutlineArrowRight size="18px" />
              </Box>
            </Link>
          )}
        </Box>
      </Box>
    </WidgetWrapper>
  );
};
