import { useContext, useEffect, useMemo, useState, useCallback } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import { useBooleanState } from '@vyce/core/src/hooks';
import {
  getDidntClockedOutWorkersRequest,
  getTimeLogsByShiftRequest,
  getDidntClockOutRolesRequest,
} from '@vyce/core/src/api/time';
import { formatTimeSortModel } from '@vyce/core/src/utils/sorting';
import { CheckOutWorkerDTO } from '@vyce/core/src/api/types';
import { useInterval } from '@vyce/core/src/hooks/useInterval';
import { TIME_INTERFACE_PERMISSIONS, TimeLogByShiftDateItem } from '@vyce/core/src/types';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { useTabFocus } from '@vyce/core/src/hooks/useTabFocus';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { formatDate, getYesterday } from '@vyce/core/src/utils';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';

import { Props } from '../types';

const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'desc' }];

export const useWidgetData = ({ selectedCompanyId, locations, userPermissions }: Props) => {
  const [workers, setWorkers] = useState<CheckOutWorkerDTO[]>([]);
  const [dateOfTimeLog, setDateOfTimeLog] = useState(getYesterday());
  const [selectedWorker, setSelectedWorker] = useState<CheckOutWorkerDTO | null>(null);
  const [isSmallView, setSmallView] = useState(false);
  const [isShowSearchField, setShowSearchFieldTrue] = useBooleanState(false);
  const [roleOptions, setRoleOptions] = useState<{ id: string; name: string }[]>([]);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [filters, setFilters] = useState<{ roles: string[]; site_ids: string[] } | null>(null);
  const [logByShift, setLogByShift] = useState<TimeLogByShiftDateItem | null>(null);
  const [
    isExpandTimeLogsInformationDialog,
    openExpandTimeLogsInformationDialog,
    closeExpandTimeLogsInformationDialog,
  ] = useBooleanState(false);
  const [isEditSingleTimeLogDialogOpen, openEditSingleTimeLogDialog, closeEditSingleTimeLogDialog] =
    useBooleanState(false);
  const [isFilterDialogOpen, openFilterDialog, closeFilterDialog] = useBooleanState(false);
  const { handleServerError } = useContext(NotificationContext);

  const { isMobile } = useContext(DeviceContext);

  const methods = useForm<{ locations: Record<string, boolean>; roles: Record<string, boolean> }>({
    defaultValues: { locations: {}, roles: {} },
  });

  const { isTabFocused } = useTabFocus();
  const {
    sortModel,
    offset,
    total,
    substring,
    setTotal,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({
    defaultSortModel,
  });

  const roles = methods.watch('roles');
  const formLocations = methods.watch('locations');

  const isFilterWasActivated =
    Object.entries(roles).some(item => item[1] === false) ||
    Object.entries(formLocations).some(item => item[1] === false);

  const locationOptions = useMemo(() => {
    if (!locations) return [];
    const options = locations.map(item => ({ id: item.uuid as string, name: item.name }));
    return options;
  }, [locations]);

  const cnaViewWorker = useMemo(
    () => (userPermissions?.length ? userPermissions.includes(TIME_INTERFACE_PERMISSIONS.USERS) : true),
    [userPermissions]
  );

  const filterSettings = useMemo(
    () => [
      {
        options: locationOptions,
        title: 'Which locations should be displayed?',
        type: 'locations',
      },
      {
        options: roleOptions,
        title: 'Which roles should be displayed?',
        type: 'roles',
      },
    ],
    [locationOptions, roleOptions]
  );

  const restoreFilters = () => {
    const data = methods.getValues();
    const restoredRoles = Object.keys(data.roles).reduce((sum, curr) => {
      sum[curr] = true;
      return sum;
    }, {} as Record<string, boolean>);

    const restoredLocations = Object.keys(data.locations).reduce((sum, curr) => {
      sum[curr] = true;
      return sum;
    }, {} as Record<string, boolean>);

    methods.reset({ locations: restoredLocations, roles: restoredRoles });
    handleChangeFilter({ locations: restoredLocations, roles: restoredRoles });
  };

  const getTimeLogsByShift = useCallback(
    async ({ userId, date, site_id }: { userId: string; date: string; site_id: string }) => {
      if (!selectedCompanyId) {
        return;
      }
      try {
        setLoadingTrue();
        const { data } = await getTimeLogsByShiftRequest(selectedCompanyId, userId, {
          start_date: date,
          end_date: date,
          site_id,
        });
        if (data.date_items.length && data.date_items[0].time_logs.length > 1) {
          openExpandTimeLogsInformationDialog();
        } else {
          openEditSingleTimeLogDialog();
        }
        setLogByShift(data.date_items[0]);

        setLoadingFalse();
      } catch (e) {
        setLoadingFalse();
        console.error(e);
      }
    },
    [selectedCompanyId]
  );

  const getRoles = async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      const { data } = await getDidntClockOutRolesRequest({
        companyId: selectedCompanyId,
        payload: {
          offset: 0,
          limit: 100,
          time_period: {
            gte: dayjs(dateOfTimeLog).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            lte: dayjs(dateOfTimeLog).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          },
        },
      });
      setRoleOptions(data.items.map(item => ({ id: item, name: item })));
    } catch (e) {
      handleServerError(e);
    }
  };

  const getMembers = async () => {
    if (!selectedCompanyId || !isTabFocused) {
      return;
    }

    setLoadingTrue();
    try {
      const { data } = await getDidntClockedOutWorkersRequest({
        companyId: selectedCompanyId,
        payload: {
          offset: offset,
          limit: GRID_PAGE_SIZE,
          order_by: formatTimeSortModel<CheckOutWorkerDTO>(sortModel),
          time_period: {
            gte: dayjs(dateOfTimeLog).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            lte: dayjs(dateOfTimeLog).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          },
          worker_substring: substring,
          ...filters,
        },
      });

      const transformedData = data.items.map(item => ({
        ...item,
        checked_out: formatDate(item.checked_out, 'YYYY-MM-DD'),
      }));
      setTotal(data.count);
      setWorkers(transformedData);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const handleChangeFilter = (data: {
    locations: Record<string, boolean>;
    roles: Record<string, boolean>;
  }) => {
    const filteredLocations = Object.keys(data?.locations).filter(
      item => data.locations[item] || data.locations[item] === undefined
    );
    const filteredRoles = Object.keys(data.roles).filter(
      item => data.roles[item] || data.roles[item] === undefined
    );
    setFilters({ roles: filteredRoles, site_ids: filteredLocations });
  };

  const onSubmit = async () => {
    const data = methods.getValues();
    handleChangeFilter(data);
  };

  const handleEditButtonClick = (worker: CheckOutWorkerDTO) => {
    getTimeLogsByShift({
      userId: worker.user_id,
      date: formatDate(worker.checked_out, 'YYYY-MM-DD'),
      site_id: worker.site_id,
    });
    setSelectedWorker(worker);
  };

  const handlePickTheNextWorker = () => {
    const index = workers.findIndex(item => item.user_id === selectedWorker?.user_id);
    if (workers[index + 1]) {
      handleEditButtonClick(workers[index + 1]);
    } else {
      handleCloseSingleEditTimeLogsDialog();
    }
  };

  const handleDateToChange = (date: any) => {
    setDateOfTimeLog(formatDate(date, 'YYYY-MM-DD'));
  };

  const switchFromEditToExpand = () => {
    openExpandTimeLogsInformationDialog();
    closeEditSingleTimeLogDialog();
  };

  const handleCloseExpandTimeLogsInformationDialog = () => {
    closeExpandTimeLogsInformationDialog();
    setLogByShift(null);
    setSelectedWorker(null);
  };

  const handleCloseSingleEditTimeLogsDialog = () => {
    closeEditSingleTimeLogDialog();
    setLogByShift(null);
    setSelectedWorker(null);
  };

  const actionsAfterDeletingTimeLog = () => {
    handleCloseExpandTimeLogsInformationDialog();
    getMembers();
  };

  useInterval(getMembers, 10000);

  useEffect(() => {
    getMembers();
  }, [selectedCompanyId, offset, sortModel, dateOfTimeLog, substring, filters]);

  useEffect(() => {
    getRoles();
  }, [selectedCompanyId, dateOfTimeLog]);

  return {
    methods,
    workers,
    total,
    selectedWorker,
    loading,
    sortModel,
    cnaViewWorker,
    isMobile,
    dateOfTimeLog,
    logByShift,
    isEditSingleTimeLogDialogOpen,
    isExpandTimeLogsInformationDialog,
    isFilterDialogOpen,
    isSmallView,
    isShowSearchField,
    filterSettings,
    isFilterWasActivated,
    onSubmit,
    restoreFilters,
    openFilterDialog,
    closeFilterDialog,
    closeEditSingleTimeLogDialog,
    handlePageChange,
    handleSortModelChange,
    setSelectedWorker,
    handleSearchChange,
    handleDateToChange,
    handleEditButtonClick,
    switchFromEditToExpand,
    handleCloseExpandTimeLogsInformationDialog,
    actionsAfterDeletingTimeLog,
    getMembers,
    handlePickTheNextWorker,
    setSmallView,
    setShowSearchFieldTrue,
  };
};
